import React, {ReactElement, useEffect, useRef} from 'react';
import {ReactComponent as IconTimer} from '../../../sprite/icon-timer.svg';
import {ReactComponent as IconEye} from '../../../sprite/icon-eye.svg';
import {ReactComponent as IconCopy} from '../../../sprite/icon-copy.svg';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {fetchArticleAction, fetchArticlesCardsAction} from '../../../store/api-actions';
import PlugReturn from '../../plug/plug-return/plug-return';
import {AppRoute} from '../../../const/common';
import Plug from '../../plug/plug';
import {DateType, parseDate} from '../../../utils/date';
import Breadcrumbs from '../../page/breadcrumbs/breadcrumbs';
import {useCopyToClipboard} from 'usehooks-ts';
import {toast} from 'react-toastify';
import ArticleCard from '../article-card/article-card';
import ReactDOM from 'react-dom';
import Seo from '../../service/seo/seo';
import {getRandomSubarray} from '../../../utils/common';

const createRegistrationOffer = (): ReactElement => {
  return (
    <>
      <img src="/img/logo.svg" alt="Task Store"/>
      <div className="article__join-inner">
        <b>Еще нет ТЗ? Пиши скорее нам!</b>
        <a className="button button_view--primary button_size--s busi text_view--primary-inverse" href={AppRoute.Catalog}>Купить ТЗ!</a>
      </div>
    </>
  );
}

const createSubscribeOffer = () => {
  const offer = document.createElement('div');
  offer.classList.add('article__subscribe');
  offer.innerHTML = `<img src="/img/logo.svg" alt="Task Store">
  <div class="article__subscribe-inner">
    <b>
      <span>Первый интернет магазин покупки технических заданий</span>
    </b>
  </div>`;

  return offer;
}

export default function Article(): ReactElement {
  const dispatch = useAppDispatch();
  const articleContentRef = useRef<HTMLInputElement>(null);

  const [copiedText, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => () => {
    copy(text)
      .then(() => {
        toast('Ссылка на статью скопирована', {type: 'success'});
      })
      .catch(error => {
        toast('Попробуйте снова', {type: 'warning'});
      })
  }

  let article = useAppSelector((state) => state.article);
  const articles = useAppSelector((state) => state.articleCards);

  let moreArticles = articles.filter((moreArticle) => {
    return moreArticle.title !== article?.title;
  });

  const randomMoreArticles = getRandomSubarray(moreArticles, 3);

  useEffect(() => {
    dispatch(fetchArticlesCardsAction({perPage: 48, currentPage: 1}));
  }, []);

  const params = useParams();

  useEffect(() => {
    params.slug && dispatch(fetchArticleAction({slug: params.slug}));
  }, [params]);

  useEffect(() => {
    const articleNode = articleContentRef.current;
    if (articleNode !== null) {
      const faqNodes = articleNode.querySelectorAll('.faq');

      faqNodes.forEach((faqNode) => {
        const question = faqNode.querySelector(':first-child');

        question?.addEventListener('click', () => {
          faqNode.classList.toggle('open');
        });
      });

      const headers = articleNode.querySelectorAll('h2, .h2');

      headers.forEach((header, index) => {
        switch (index) {
          case 0:
            if (!articleNode.querySelector('.article__join')) {

              const registrationOfferWrapper = document.createElement('div');
              registrationOfferWrapper.id = 'registration-offer-wrapper'
              registrationOfferWrapper.classList.add('article__join');
              articleNode?.insertBefore(registrationOfferWrapper, header);

              ReactDOM.render(createRegistrationOffer(), registrationOfferWrapper);
            }
            break;
          case 1:
            if (!articleNode.querySelector('.article__subscribe')) {
              articleNode?.insertBefore(createSubscribeOffer(), header);
            }
            break;
        }
      });
    }
  }, [article]);

  return (
    <>
      {article && <Seo
        title={article.metaTitle}
        desc={article.metaDesc}
        url={window.location.href}
      />}

      <Breadcrumbs
        firstPage={'Статья'}
        secondPage={{
          name: 'Блог',
          url: AppRoute.Blog,
        }}
      />

      <main className="article container">
        {article ? <>
          <header className="article__header">
            <time className="article__date" dateTime={article.date}>{parseDate(article.date, DateType.MonthName)}</time>

            <h1 className="article__title">
              {article.title}
            </h1>

            <img
              src={article.poster}
              alt={article.title}
              width={1140}
              height={768}
            />
          </header>

          <div className="article__info">
            <div className="article__info-col article__info-col--left">
              <p className="">
                Редакция <a className="link link_view--primary text_view--accent" href="https://it-tz.ru/">it-tz.ru</a>
              </p>

              <p className="article__info-time">
                <IconTimer
                  className="icon icon_color--accent select__arrow_view--accent"
                  width={24}
                  height={24}
                />

                Время чтения: {article.readTime} мин
              </p>
            </div>

            <div className="article__info-col article__info-col--right">
              <p className="article__info-view">
                <IconEye
                  className="icon icon_color--accent select__arrow_view--accent"
                  width={24}
                  height={24}
                />

                {article.views}
              </p>

              <button className="article__info-copy" onClick={handleCopy(window.location.href)}>
                <IconCopy
                  className="icon icon_color--tertiary"
                  width={24}
                  height={24}
                />
              </button>
            </div>
          </div>

          <div
            ref={articleContentRef}
            className="article__content"
            dangerouslySetInnerHTML={{__html: article.content}}
          />

          <h2 className="title title_size--m space_bottom-l">Читайте также</h2>

          <div className="blog__list space_bottom-5xl grid grid_col--3 grid_gap--s-m-m">
            {randomMoreArticles.map((article) => (
              <ArticleCard
                title={article.title}
                date={article.date}
                view={article.view}
                preview={article.preview}
                slug={article.slug}
                key={article.id}
              ></ArticleCard>
            ))}
          </div>
        </> : <Plug
          caption={'Статья не найдена'}
        >
          <PlugReturn
            caption={'Вернуться в блог'}
            link={AppRoute.Blog}
          />
        </Plug>}
      </main>
    </>
  );
}
