import {ReactElement} from 'react';
import {AppRoute} from '../../../const/common';
import {Link} from 'react-router-dom';

type PlugReturnProps = {
  caption: string;
  link: AppRoute;
}

export default function PlugReturn({caption, link}: PlugReturnProps): ReactElement {
  return (
    <Link
      to={link}
      className="empty-plug__action button button_view--primary button_size--s"
    >
      {caption}
    </Link>
  )
}
