import React, {ReactElement} from 'react';
import cn from 'classnames';

type FormSubmitButtonProps = {
  onSubmit: () => void;
  children: string;
  className?: string;
}

export default function FormSubmitButton({onSubmit, className, children}: FormSubmitButtonProps): ReactElement {
  return (
    <button
      className={cn(
        'button',
        'button_view--primary',
        'button_size--s',
        'text_align--center',
        'button_size--full',
        className
      )}
      type="submit"
      onClick={onSubmit}
    >
      {children}
    </button>
  );
}
