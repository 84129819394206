import React, {ReactElement} from 'react';
import Header from '../../components/page/header/header';
import Footer from '../../components/page/footer/footer';
import Breadcrumbs from '../../components/page/breadcrumbs/breadcrumbs';
import Privacy from '../../components/privacy/privacy';
import ModalContainer from '../../components/page/modal-container/modal-container';
import {Helmet} from 'react-helmet-async';
import Title from '../../components/page/head/title/title';
import {CanonicalUrl, PageDesc, PageTitle} from '../../const/seo';
import Seo from '../../components/service/seo/seo';

export default function PrivacyScreen(): ReactElement {
  return (
    <>
      <Seo
        title={PageTitle.Privacy}
        url={CanonicalUrl.Privacy}
        desc={PageDesc.Main}
      />

      <Header/>
      <Breadcrumbs
        firstPage={'Политика конфиденциальности'}
      />
      <Privacy/>
      <Footer/>
      <ModalContainer />
    </>
  );
}
