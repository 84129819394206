import {ReactElement} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {Good, PlatformName, PlatformType} from '../../../types/goods';
import {toggleFilterPlatform} from '../../../store/actions';

type goodsPlatforms = {
  [key in PlatformType]?: number;
}

type FilterPlatformType = {
  filteredGoods: Good[];
}

export default function FilterPlatform({filteredGoods}: FilterPlatformType): ReactElement {
  const filter = useAppSelector((state) => state.filter);

  const dispatch = useAppDispatch();

  const goodsPlatforms: goodsPlatforms = {};

  filteredGoods.forEach((good) => {
    if (!good.platform) {
      return;
    }

    const platform = good.platform;

    const prevValue = goodsPlatforms[platform];

    if (prevValue) {
      goodsPlatforms[platform] = prevValue + 1;
    } else {
      goodsPlatforms[platform] = 1;
    }
  });

  return (
    <>
      {Object.values(PlatformType).map((platform) => (
        <p className="input__wrapper input__wrapper--toggle" key={`platform-${platform}`}>
          <input
            className="checkbox"
            id={`platform-${platform}`}
            type="checkbox"
            defaultValue={`platform-${platform}`}
            onChange={() => {
              dispatch(toggleFilterPlatform(platform));
            }}
            checked={filter.platforms.some((filterPlatform) => filterPlatform === platform)}
          />
          <label htmlFor={`platform-${platform}`} aria-hidden="true" />
          <label
            htmlFor={`platform-${platform}`}
            className="input__description text_weight--400">
            {PlatformName[platform] + ' '}
            <span className="filter__category-count text_view--tertiary">
              ({goodsPlatforms[platform] || 0})
            </span>
          </label>
        </p>

      ))}
    </>
  );
}
