import React, {ReactElement} from 'react';
import Header from '../../components/page/header/header';
import Breadcrumbs from '../../components/page/breadcrumbs/breadcrumbs';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import Profile from '../../components/profile/profile';

import {CanonicalUrl, PageTitle} from '../../const/seo';
import Seo from '../../components/service/seo/seo';

export default function ProfileScreen(): ReactElement {
  return (
    <>
      <Seo
        title={PageTitle.Profile}
        url={CanonicalUrl.Profile}
      />

      <Header/>
      <Breadcrumbs
        firstPage={'Профиль'}
      />
      <Profile/>
      <Footer/>
      <ModalContainer />
    </>
  );
}
