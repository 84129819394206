import React, {ReactElement} from 'react';
import FormTitle from '../../atomas/form-title/form-title';
import FormSubmitButton from '../../atomas/form-submit-button/form-submit-button';
import {hideModal} from '../../../store/actions';
import Modal from '../modal';
import {useAppDispatch} from '../../../hooks';

export default function ModalResetPasswordConfirm(): ReactElement {
  const dispatch = useAppDispatch();

  return (
    <Modal>
      <form
        className="modal__inner"
        spellCheck="false"
        noValidate
        onSubmit={event => event.preventDefault()}
      >
        <FormTitle>Восстановление доступа</FormTitle>

        <p
          className="input__wrapper input__wrapper--field text_view--tertiary space_bottom-l text_size--14 modal__desc">
          Доступ к вашему аккаунту был восстановлен
        </p>

        <FormSubmitButton
          onSubmit={() => dispatch(hideModal())}
          className={'modal__confirm modal__bottom'}
        >
          Ок
        </FormSubmitButton>
      </form>
    </Modal>
  );
}
