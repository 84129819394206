import {ReactElement, useState} from 'react';
import {ReactComponent as IconClose} from '../../sprite/icon-close.svg';
import cn from 'classnames';
import {ReactComponent as IconArrowBottom} from '../../sprite/icon-arrow-bottom.svg';
import {Link} from 'react-router-dom';
import {useAppSelector} from '../../hooks';
import {GoodType, GoodTypeName} from '../../types/goods';
import {AppRoute} from '../../const/common';
import {getCategoryList} from '../../utils/category';

type CategoryProps = {
  closeCategoryHandler: () => void;
};

type categoryListProps = {
  [slug: string]: string,
};

const createCategoryChapter = (categoryName: GoodType, categoryList: categoryListProps, key: string): ReactElement => {
  return (
    <fieldset
      key={key}
      className={cn(
        'filter__fieldset',
        'filter__fieldset--toggle',
        'filter__fieldset-category-game',
        'filter__fieldset--open'
      )}
    >
      <legend
        className="text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m"
      >
        <Link to={`${AppRoute.Catalog}${GoodTypeName[categoryName].slug}`} className="link link_view--primary link_view--hover-accent">
          {GoodTypeName[categoryName].name}
        </Link>

        <button
          className="button button_view--simple"
          type="button"
          onClick={(event) => {
            const parent = (event.target as HTMLElement).closest('.filter__fieldset');

            parent && parent.classList.toggle('filter__fieldset--open');
            parent && parent.classList.toggle('filter__fieldset--close');
          }}
        >
          <IconArrowBottom className="icon" width={24} height={24}/>
          <span className="visuallyhidden">Свернуть</span>
        </button>
      </legend>
      <div className="filter__fieldset-inner">
        {Object.entries(categoryList).map(([slug, name]) => {
          return (
            <Link to={`${AppRoute.Catalog}${GoodTypeName[categoryName].slug}/${slug}`} className="link link_view--primary link_view--hover-accent" key={slug}>
              {name}
            </Link>
          );
        })}
      </div>
    </fieldset>
  )
}

export default function Category({closeCategoryHandler}: CategoryProps): ReactElement {
  const goods = useAppSelector((state) => state.goods);

  const goodsCategory = getCategoryList(goods);

  const [isCategoryOpen, setIsCategoryOpen] = useState(true);

  return (
    <form
      className={cn(
        'catalog__category',
        'filter',
        {'filter--close': !isCategoryOpen}
      )}
    >
      <div className="filter__inner radius--16 border_view--primary">
        <div className="filter__top">
          <h3 className="filter__title title title_size--s">Каталог</h3>

          <button
            className="button button_view--simple"
            type="button"
            onClick={() => setIsCategoryOpen((prevState) => !prevState)}
          >
            <IconArrowBottom
              className="filter__top-toggle icon"
              width={24}
              height={24}
            />
            <span className="visuallyhidden">Свернуть</span>
          </button>

          <button
            className="button button_view--simple filter__close"
            type="button"
            onClick={closeCategoryHandler}
          >
            <IconClose className="icon" width={24} height={24}/>
            <span className="visuallyhidden">Закрыть</span>
          </button>
        </div>

        {
          Object.values(GoodType).map((type) => {
            if (Object.keys(goodsCategory[type]).length === 0) {
              return;
            }

            return createCategoryChapter(type, goodsCategory[type], `category-${type}`)
          })
        }
      </div>
    </form>
  );
}
