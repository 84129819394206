import React, {ReactElement} from 'react';
import {Helmet} from 'react-helmet-async';
import {CanonicalUrl, PageDesc, PageTitle} from '../../const/seo';
import Header from '../../components/page/header/header';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import Breadcrumbs from '../../components/page/breadcrumbs/breadcrumbs';
import Search from '../../components/search/search';
import Seo from '../../components/service/seo/seo';

export default function SearchScreen(): ReactElement {
  return (
    <>
      <Seo
        title={PageTitle.Search}
        url={CanonicalUrl.Search}
      />

      <Header />
      <Breadcrumbs
        firstPage={'Поиск'}
      />

      <Search/>

      <Footer />
      <ModalContainer />
    </>
  );
}
