import {ReactElement} from 'react';

type ToggleProps = {
  title: string;
  children: ReactElement;
  isOpen?: boolean;
}

export default function Details({title, children, isOpen = false}: ToggleProps): ReactElement {
  return (
    <>
      <details className="details" open={isOpen}>
        <summary className="summary">
          <h3>
            {title}
          </h3>
        </summary>

        <div className="details__answer">
          <p className="details__answer-desc">
            Ответ
          </p>

          {children}
        </div>
      </details>
    </>
  );
}
