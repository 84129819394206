export const ValidationConfig = {
  email: {
    required: {
      value: true,
      message: 'Обязательное поле',
    },
    pattern: {
      value: /[A-z0-9._%+\-]+@[A-z0-9.\-]+\.[A-z]{2,}$/,
      message: 'Неверный формат',
    }
  },

  name: {
    required: {
      value: true,
      message: 'Обязательное поле',
    },
    pattern: {
      value: /[а-яёА-ЯЁa-zA-Z]+/,
      message: 'Только русские и латинские буквы',
    }
  },

  password: {
    required: {
      value: true,
      message: 'Обязательное поле',
    },
    minLength: {
      value: 6,
      message: 'Минимум 6 символов',
    }
  },

  confirm: {
    required: {
      value: true,
      message: '',
    }
  }
}
