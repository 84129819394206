import {createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError, AxiosInstance} from 'axios';
import {APIRoute, AuthStatus} from '../const/common';
import {
  hideModal,
  loadArticle,
  loadArticles, loadFilterFields,
  loadGoods,
  loadSearchGoods,
  requireAuth,
  setArticlesCount,
  setUserData, showModal
} from './actions';
import {dropResetEmail, dropResetToken, dropToken, getToken, saveToken} from '../services/token';
import {
  ArticleData,
  BlogData, BuyGoodData,
  ConfirmResetData, EditPasswordNewData, EditProfileData, PurchaseData,
  RegistrationData,
  ResetData, SearchGoodsData,
  UserData,
  VerifyData
} from '../types/data';
import {AuthData} from '../types/data';
import {AppDispatch} from '../types/state';
import {State} from '../types/state';
import {toast} from 'react-toastify';
import {getRussianErrorMessage} from '../utils/error';
import {GoodServer} from '../types/goods';
import {ArticleServer, BlogPageData} from '../types/blog';
import {ModalType} from '../types/modalType';
import {FilterFields} from '../types/filter';

export const checkAuthAction = createAsyncThunk<void, undefined, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/checkAuth',
  async (_arg, {dispatch, extra: api}) => {
    try {
      if (!getToken()) {
        dispatch(requireAuth(AuthStatus.NoAuth));

        return;
      }

      const {data: {email, id, name}} = await api.get<UserData>(APIRoute.UserInfo);

      if (!id) {
        throw new Error();
      }

      dispatch(requireAuth(AuthStatus.Auth));
      dispatch(setUserData({
        id,
        email,
        name
      }));

    } catch {
      dispatch(requireAuth(AuthStatus.NoAuth));
    }
  },
);

export const loginAction = createAsyncThunk<void, AuthData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/login',
  async ({email, password}, {dispatch, extra: api}) => {
    try {
      const response = await api.post<UserData>(APIRoute.Login, {email, password});

      const {data: {api_token: token, id, name}} = response;

      if (!id) {
        throw new Error();
      }

      saveToken(token);
      dispatch(requireAuth(AuthStatus.Auth));
      dispatch(setUserData({
        id,
        email,
        name,
      }));
      dispatch(hideModal());
    } catch(error) {
      let errorMessage = 'Неизвестная ошибка, попробуйте снова1';

      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.message;
      }

      toast(getRussianErrorMessage(errorMessage), {type: 'error'})
    }
  },
);

export const registrationAction = createAsyncThunk<void, RegistrationData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/registration',
  async ({name, email, password, password_confirmation}, {dispatch, extra: api}) => {
    try {
      const {data: {id}} = await api.post<UserData>(APIRoute.Register, {name, email, password, password_confirmation});

      if (!id) {
        throw new Error();
      }

      toast('На почту направленно письмо, для подтверждения регистрации', {type: 'success'})

      dispatch(hideModal());
    } catch(error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data.message;

        toast(getRussianErrorMessage(errorMessage), {type: 'error'});
      }
    }
  },
);

export const verifyAction = createAsyncThunk<void, VerifyData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/verify',
  async ({verifyNum, verifyData, verifyExpires, verifySignature}, {dispatch, extra: api}) => {
    try {
      await api.get<UserData>(`${APIRoute.Verify}/${verifyNum}/${verifyData}?expires=${verifyExpires}&signature=${verifySignature}`);

      dispatch(hideModal());

      toast('Почта подтверждена', {type: 'success'});
    } catch(error) {
      toast('Почта подтверждена', {type: 'error'});

      let errorMessage = 'Неизвестная ошибка, попробуйте снова';

      if (error instanceof AxiosError) {
        errorMessage = error.response?.data.message;
      }

      toast(getRussianErrorMessage(errorMessage), {type: 'error'})
    }
  },
);

export const logoutAction = createAsyncThunk<void, undefined, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/logout',
  async (_arg, {dispatch, extra: api}) => {
    try {
      await api.post(APIRoute.Logout);
      dropToken();
      dispatch(requireAuth(AuthStatus.NoAuth));

      toast('Вы вышли', {type: 'success'});
    } catch {
      toast('Попробуйте снова', {type: 'error'});
    }
  },
);

export const resetAction = createAsyncThunk<void, ResetData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/reset',
  async ({email}, {dispatch, extra: api}) => {
    try {
      await api.post(APIRoute.Reset, {email});

      toast('На почту отправлено письмо', {type: 'success'});
      dispatch(hideModal());
    } catch(error) {
      if (error instanceof AxiosError) {
        toast(getRussianErrorMessage(error.response?.data.message), {type: 'error'})
      }
    }
  },
);

export const confirmResetAction = createAsyncThunk<void, ConfirmResetData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/confirmReset',
  async ({token, password, password_confirmation, email}, {dispatch, extra: api}) => {
    try {
      await api.post(APIRoute.ConfirmReset, {token, password, password_confirmation, email});

      dispatch(showModal(ModalType.ResetPasswordConfirm));

      dropResetEmail();
      dropResetToken();
    } catch(error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data.message;

        toast(getRussianErrorMessage(errorMessage), {type: 'error'})
      }
    }
  },
);

export const editProfileAction = createAsyncThunk<void, EditProfileData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/edit',
  async ({name, email, current_password}, {dispatch, extra: api}) => {
    try {
      await api.post(APIRoute.Edit, {name, email, current_password});

      toast('Данные обновлены', {type: 'success'});
    } catch(error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data.message;

        toast(getRussianErrorMessage(errorMessage), {type: 'error'})
      }
    }
  },
);

export const editPasswordAction = createAsyncThunk<void, EditPasswordNewData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/edit',
  async ({password, password_confirmation, current_password}, {dispatch, extra: api}) => {
    try {
      await api.post(APIRoute.Edit, {password, password_confirmation, current_password});

      toast('Пароль обновлен', {type: 'success'});

      dispatch(hideModal());
    } catch(error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data.message;

        toast(getRussianErrorMessage(errorMessage), {type: 'error'})
      }
    }
  },
);

export const fetchGoodsAction = createAsyncThunk<void, undefined, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'data/fetchGoods',
  async (_arg, {dispatch, extra: api}) => {
    const {data} = await api.get<GoodServer[]>(APIRoute.Goods);
    dispatch(loadGoods(data));
  },
);

export const fetchFilterFieldsAction = createAsyncThunk<void, undefined, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'data/fetchFilterFields',
  async (_arg, {dispatch, extra: api}) => {
    const {data} = await api.get<FilterFields>(APIRoute.FilterFields);
    dispatch(loadFilterFields(data));
  },
);

export const fetchArticlesCardsAction = createAsyncThunk<void, BlogData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'data/fetchArticles',
  async ({perPage, currentPage, phrase}, {dispatch, extra: api}) => {
    const params = new URLSearchParams();
    params.append('per_page', String(perPage));
    params.append('page', String(currentPage));

    phrase && params.append('phrase', phrase);

    const {data} = await api.get<BlogPageData>(APIRoute.Blog, {params});

    dispatch(loadArticles(data.data));
    dispatch(setArticlesCount(data.total));
  },
);

export const fetchArticleAction = createAsyncThunk<void, ArticleData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'data/fetchArticles',
  async ({slug}, {dispatch, extra: api}) => {

    const {data} = await api.get<ArticleServer>(`${APIRoute.Blog}/${slug}`);

    dispatch(loadArticle(data));
  },
);

export const fetchSearchGoodsAction = createAsyncThunk<void, SearchGoodsData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'data/fetchGoods',
  async ({phrase}, {dispatch, extra: api}) => {
    const params = new URLSearchParams();
    params.append('phrase', phrase);

    const {data} = await api.get<GoodServer[]>(APIRoute.Goods, {params});
    dispatch(loadSearchGoods(data));
  },
);

export const buyGoodsAction = createAsyncThunk<void, BuyGoodData, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/edit',
  async ({id}, {dispatch, extra: api}) => {
    try {
      const {data} = await api.post<PurchaseData>(`/catalog/${id}/purchase`);

      window.open(data.data, "_blank", "noreferrer");
    } catch(error) {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data.message;

        toast(getRussianErrorMessage(errorMessage), {type: 'error'})
      }
    }
  },
);
