import React, {ReactElement, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {AppRoute} from '../../../const/common';
import {FieldValues, RegisterOptions, useFormContext} from 'react-hook-form';
import cn from 'classnames';

type InputCheckboxConfirmProps = {
  formName: string;
  name: string;
  children: ReactNode;
  validation?: RegisterOptions<FieldValues, string>,
}

export default function InputCheckboxConfirm({formName, name, children, validation}: InputCheckboxConfirmProps): ReactElement {
  const {
    register,
    formState: {errors},
  } = useFormContext();

  const error = errors[name];

  const id = `${formName}-${name}`;

  return (
    <p className="input__wrapper input__wrapper--toggle space_bottom-l modal__bottom">
      <input
        className={cn(
          'checkbox',
          {'field_state--error': error}
        )}
        id={id}
        type="checkbox"
        data-validation-type="checkbox"

        {...register(name, validation)}
      />
      <label
        htmlFor={id}
        aria-hidden="true"
      />
      <label
        htmlFor={id}
        className="input__description text_weight--400 text_size--14"
      >
        {children}
      </label>
    </p>
  );
}
