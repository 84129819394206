import React, {ReactElement} from 'react';
import {PageTitle} from '../../const/seo';
import {Helmet} from 'react-helmet-async';
import Header from '../../components/page/header/header';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import Article from '../../components/blog/article/article';

export default function ArticleScreen(): ReactElement {
  return (
    <>
      <Header/>
      <Article/>
      <Footer/>
      <ModalContainer />
    </>
  );
}
