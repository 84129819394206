import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/app';
import reportWebVitals from './reportWebVitals';
import './scss/main.scss'
import {Provider} from 'react-redux';
import {store} from './store';
import {checkAuthAction, fetchFilterFieldsAction, fetchGoodsAction} from './store/api-actions';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const documentHeight = () => {
  document
    .documentElement
    .style
    .setProperty('--doc-height', `${window.innerHeight}px`);
};

documentHeight();

window.addEventListener('resize', () => {
  documentHeight();
});

store.dispatch(fetchGoodsAction());
store.dispatch(checkAuthAction());
store.dispatch(fetchFilterFieldsAction());

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
  {/*<React.StrictMode>*/}
    <ToastContainer />
    <Provider store={store}>
      <App />
    </Provider>
  {/*</React.StrictMode>*/}
  </>
);

// const rootElement = document.getElementById("root");
//
// if (rootElement && rootElement.hasChildNodes()) {
//   console.log('prerender', rootElement, rootElement.hasChildNodes())
//   hydrate(
//     <>
//       {/*<React.StrictMode>*/}
//       <ToastContainer />
//       <Provider store={store}>
//         <App />
//       </Provider>
//       {/*</React.StrictMode>*/}
//     </>,
//     rootElement
//   );
// } else {
//   console.log('common render')
//   const root = ReactDOM.createRoot(
//     rootElement as HTMLElement
//   );
//
//   root.render(
//     <>
//       {/*<React.StrictMode>*/}
//       <ToastContainer />
//       <Provider store={store}>
//         <App />
//       </Provider>
//       {/*</React.StrictMode>*/}
//     </>
//   );
// }

reportWebVitals();
