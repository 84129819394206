import {ReactElement, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {toggleFilterGenre} from '../../../store/actions';
import cn from 'classnames';
import {ReactComponent as IconArrowBottom} from '../../../sprite/icon-arrow-bottom.svg';

type goodsGenres = {
  [key: string]: {
    count: number;
    name: string;
  };
}

export default function FilterGenres(): ReactElement {
  const [isSpoilerOpen, setIsSpoilerOpen] = useState(false);

  const goods = useAppSelector((state) => state.goods);
  const currentType = useAppSelector((state) => state.currentType);
  const currentCategory = useAppSelector((state) => state.currentCategory);
  const filter = useAppSelector((state) => state.filter);

  const dispatch = useAppDispatch();

  const goodsGenres: goodsGenres = {};

  goods.forEach((good) => {
    if (good.type !== currentType) {
      return;
    }
    if (currentCategory && good.category.slug !== currentCategory) {
      return;
    }

    const genre = good.genre;

    const prevValue = goodsGenres[genre];

    if (prevValue) {
      goodsGenres[genre].count = prevValue.count + 1;
    } else {
      goodsGenres[genre] = {
        count: 1,
        name: good.nameGenre,
      };
    }
  });

  const createField = (genre: string) => (
    <p className="input__wrapper input__wrapper--toggle" key={`genre-${genre}`}>
      <input
        className="checkbox"
        id={`genre-${genre}`}
        type="checkbox"
        defaultValue={`genre-${genre}`}
        onChange={() => {
          dispatch(toggleFilterGenre({
            value: genre,
            name: goodsGenres[genre].name,
          }));
        }}
        checked={filter.genres.some((filterGenre) => filterGenre.value === genre)}
      />
      <label htmlFor={`genre-${genre}`} aria-hidden="true" />
      <label
        htmlFor={`genre-${genre}`}
        className="input__description text_weight--400">
        {goodsGenres[genre].name + ' '}
        <span className="filter__category-count text_view--tertiary">
          ({goodsGenres[genre].count})
        </span>
      </label>
    </p>
  );

  const genres = Object.keys(goodsGenres);

  if (genres.length > 4) {
    return (
      <>
        {genres.slice(0, 4).map((genre) => createField(genre))}

        <div
          className={cn(
            'filter__fieldset-spoiler',
            {'filter__fieldset-spoiler--open': isSpoilerOpen}
          )}
        >
          {genres.slice(4).map((genre) => createField(genre))}
        </div>

        <button
          className="filter__fieldset-spoiler-open button button_size--narrow text_view--accent"
          type="button"
          onClick={
            () => setIsSpoilerOpen((prevState) => !prevState)
          }
        >
          {isSpoilerOpen ? 'Скрыть' : 'Показать еще'}
          <IconArrowBottom className="icon" width={24} height={24}/>
        </button>
      </>
    );
  }

  return (
    <>
      {genres.map((genre) => createField(genre))}
    </>
  );
}
