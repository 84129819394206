export enum ModalType {
  Login = 'LOGIN',
  Registration = 'REGISTRATION',
  ConfirmPurchase = 'CONFIRM_PURCHASE',
  SendCode = 'SEND_CODE',
  SendCodeConfirm = 'SEND_CODE_CONFIRM',
  ResetPassword = 'RESET_PASSWORD',
  ResetPasswordConfirm = 'RESET_PASSWORD_CONFIRM',
  ModalEditPassword = 'MODAL_EDIT_PASSWORD',
  Exit = 'EXIT',
}
