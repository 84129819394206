import React, {ReactElement} from 'react';
import GoodsCard from '../../goods/goods-card/goods-card';
import ArticleCardWide from '../../blog/article-card-wide/article-card-wide';
import Plug from '../../plug/plug';
import PlugReturn from '../../plug/plug-return/plug-return';
import {AppRoute} from '../../../const/common';
import {SearchType} from '../../search/search-list/search-list';
import {useAppSelector} from '../../../hooks';
import CartGoodsCard from '../../goods/cart-goods-card/cart-goods-card';

export default function Cart(): ReactElement {
  const cartGoods = useAppSelector((state) => state.cartGoods);

  return (
    <main className="profile__main-page">
      <h1 className="search__title title title_size--l container">Корзина</h1>

      <section className="search container">
        <ul className="search__list">
          {cartGoods.map((good) => (
            <CartGoodsCard
              title={good.desc}
              id={good.id}
              view={good.view}
              purchases={good.purchases}
              genre={good.genre}
              nameGenre={good.nameGenre}
              price={good.price}
              oldPrice={good.oldPrice}
              images={good.images}
              graphic={good.graphic}
              platform={good.platform}
              key={good.id}
            ></CartGoodsCard>
          ))}

          {cartGoods.length === 0 && <Plug
            caption={`Вы не добавили ни одного товара в корзину`}
          >
            <PlugReturn
              caption={'Перейти в каталог'}
              link={AppRoute.Catalog}
            />
          </Plug>}
        </ul>
      </section>
    </main>
  );
}
