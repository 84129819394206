import {DateType, parseDate} from '../../../utils/date';

import {ReactComponent as IconEye} from '../../../sprite/icon-eye.svg';
import {ReactComponent as IconTimer} from '../../../sprite/icon-timer.svg';
import {ReactComponent as IconCalendar} from '../../../sprite/icon-calendar.svg';
import {ReactComponent as IconBook} from '../../../sprite/icon-book.svg';
import {Link} from 'react-router-dom';
import React from 'react';

type ArticleCardProps = {
  title: string,
  id: number,
  date: string,
  view: number,
  preview: string,
  slug: string,
};

export default function ArticleCardWide({title, id, date, view, preview, slug}: ArticleCardProps) {
  return (
    <li className="goods-card">
      <Link
        to={`/blog/${slug}`}
      >
        <img src={preview} alt={title} width={329} height={466} className="goods-card__img" />
      </Link>
      <div className="goods-card__inner">
        <ul className="cheap__list space_bottom-s">
          <li>
            <Link
              to={`/blog/${slug}`}
              className="cheap cheap_view--bordered cheap_view--bordered-hover text_size--12 cheap_size--s link link_view--primary"
            >
              <IconEye className="icon" width={16} height={16}/>
              <span className="cheap__count show--after-mobile goods-card__view">
                {view}
              </span>
              <span>
                Просмотров
              </span>
            </Link>
          </li>
          <li>
            <Link
              to={`/blog/${slug}`}
              className="cheap cheap_view--bordered cheap_view--bordered-hover text_size--12 cheap_size--s link link_view--primary"
            >
              <IconCalendar className="icon" width={16} height={16}/>
              <time className="cheap__count goods-card__purchases" dateTime={date}>
                {parseDate(date, DateType.MonthName)}
              </time>
            </Link>
          </li>
        </ul>
        <Link
          className="link_view--primary space_bottom-m"
          to={`/blog/${slug}`}
        >
          <h3 className="goods-card__title text_size--14-16-16 text_line-height--150 text_view--base">
            {title}
          </h3>
        </Link>
        <div className="goods-card__inner-bottom">
          {/*<div className="goods-card__prices-wrap">*/}
          {/*  <p className="goods-card__prices-caption text_size--14 text_view--tertiary">*/}
          {/*    Цена:*/}
          {/*  </p>*/}
          {/*  <p className="goods-card__prices">*/}
          {/*    <span className="text_size--20 text_line-height--100 text_weight--500 text_view--accent">*/}
          {/*      {convertTriads(price)} ₽*/}
          {/*    </span>*/}
          {/*    <del className="goods-card__prices-old text_size--14 text_view--tertiary">*/}
          {/*      {convertTriads(oldPrice)} ₽*/}
          {/*    </del>*/}
          {/*  </p>*/}
          {/*</div>*/}
          <ul className="cheap__list space_bottom-m">
            <li>
              <p
                className="cheap cheap_view--accent-tertiary cheap_view--accent-tertiary-hover text_size--12 cheap_size--s"
              >
                <IconBook className="icon" width={16} height={16}/>
                <span>Блог</span>
              </p>
            </li>
            <li>
              <p
                className="cheap cheap_view--accent-tertiary cheap_view--accent-tertiary-hover text_size--12 cheap_size--s"
              >
                <IconTimer className="icon" width={16} height={16}/>
                <span>5 мин</span>
              </p>
            </li>
          </ul>
          <Link
            to={`/blog/${slug}`}
            className="button_view--simple goods-card__button button button_view--primary button_size--s"
          >
            Подробнее
          </Link>
        </div>
      </div>
    </li>
  );
}
