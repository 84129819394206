import {ReactElement, useEffect, useState} from 'react';
import ReactSlider from 'react-slider';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {setPrice} from '../../../store/actions';
import {convertTriads} from '../../../utils/common';
import {RANGE_STEP} from '../../../const/common';

export default function FilterPrice(): ReactElement {
  const filter = useAppSelector((state) => state.filter);

  const [currentPrice, setCurrentPrice] = useState(filter.price);

  useEffect(() => {
    setCurrentPrice(filter.price);
  }, [filter.price])

  const dispatch = useAppDispatch();

  return (
    <>
      <p className="input__wrapper input__wrapper--toggle filter__range-wrap">
          <span className="field__wrapper text_size--14">
            <input
              className="field filter__field-from"
              type="text"
              readOnly
              tabIndex={-1}
              value={`${convertTriads(currentPrice[0])} ₽`}
              onChange={(event) => event.preventDefault()}
            />
          </span>
        <span className="field__wrapper text_size--14">
          <input
            className="field filter__field-to"
            type="text"
            readOnly
            tabIndex={-1}
            value={`${currentPrice[1] !== filter.priceRange[1] ? convertTriads(currentPrice[1]) + ' ₽' : '∞'}`}
          />
        </span>
      </p>

      <div className="filter__range input-range">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="input-range__thumb"
          trackClassName="input-range__track"
          value={filter.price}
          ariaLabel={['Leftmost thumb', 'Middle thumb', 'Rightmost thumb']}
          onChange={(value) => {
            setCurrentPrice(value);
            dispatch(setPrice(value));
          }}
          onAfterChange={(value) => dispatch(setPrice(value))}
          pearling
          minDistance={0}
          min={filter.priceRange[0]}
          max={filter.priceRange[1]}
          step={RANGE_STEP}
        />
      </div>
    </>
  );
}
