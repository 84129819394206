import {ReactElement, useEffect, useState} from 'react';
import {ReactComponent as IconProfile} from '../../../sprite/icon-profile.svg';
import {ReactComponent as IconKey} from '../../../sprite/icon-key.svg';
import {ReactComponent as IconArrowBottom} from '../../../sprite/icon-arrow-bottom.svg';
import {ReactComponent as IconPhone} from '../../../sprite/icon-phone.svg';
import {ReactComponent as IconCart} from '../../../sprite/icon-cart.svg';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {showModal} from '../../../store/actions';
import {ModalType} from '../../../types/modalType';
import {AppRoute, AuthStatus} from '../../../const/common';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {enableScroll, preventScroll} from '../../../utils/common';
import {toast} from 'react-toastify';
import {useCopyToClipboard} from 'usehooks-ts';

export default function MainNav(): ReactElement {
  const dispatch = useAppDispatch();

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  useEffect(() => {
    isMobileMenuVisible ? preventScroll() : enableScroll();
  }, [isMobileMenuVisible]);

  const authStatus = useAppSelector((state) => state.authStatus);
  const userData = useAppSelector((state) => state.user);

  const [copiedText, copy] = useCopyToClipboard();

  const handleCopy = (text: string) => () => {
    copy(text)
      .then(() => {
        toast('Телефон скопирован', {type: 'success'});
      })
      .catch(error => {
        toast('Попробуйте снова', {type: 'warning'});
      })
  }

  return (
    <>
      <nav className="main-header__main-nav main-nav">
        {authStatus !== AuthStatus.Auth && <>
          {/*<button*/}
          {/*  className="main-nav__phone button button_view--bordered button_size--s button_behavior--hide-text"*/}
          {/*  onClick={handleCopy('79772507777')}*/}
          {/*>*/}
          {/*  <IconPhone className="icon" width={20} height={20}/>*/}
          {/*  <span>+7 (977) 250-77-77</span>*/}
          {/*</button>*/}

          {/*<Link*/}
          {/*  className="main-nav__cart button button_view--bordered button_size--s button_behavior--hide-text"*/}
          {/*  to={AppRoute.Cart}*/}
          {/*>*/}
          {/*  <IconCart className="icon" width={20} height={20}/>*/}
          {/*  <span>Корзина</span>*/}
          {/*</Link>*/}

          <button
            className="main-nav__login button button_view--bordered button_size--s button_behavior--hide-text"
            onClick={() => dispatch(showModal(ModalType.Login))}
          >
            <IconKey className="icon" width={20} height={20}/>
            <span>Войти</span>
          </button>

          <button
            className="main-nav__registration button button_view--primary button_size--s button_behavior--hide-text"
            onClick={() => dispatch(showModal(ModalType.Registration))}
          >
            <IconProfile className="icon" width={20} height={20}/>
            <span>Создать аккаунт</span>
          </button>
        </>}
        {authStatus === AuthStatus.Auth && <>
          {/*<button*/}
          {/*  className="main-nav__phone button button_view--tertiary button_size--s button_behavior--hide-text"*/}
          {/*  onClick={handleCopy('79772507777')}*/}
          {/*>*/}
          {/*  <IconPhone className="icon" width={20} height={20}/>*/}
          {/*  <span>+7 (977) 250-77-77</span>*/}
          {/*</button>*/}

          <Link
            className="main-nav__cart button button_view--bordered button_size--s button_behavior--hide-text"
            to={AppRoute.Cart}
          >
            <IconCart className="icon" width={20} height={20}/>
            <span>Корзина</span>
          </Link>

          <details className="main-nav__profile select select_view--button text_size--14">
            <summary className="select__current select__current_view--accent">
              <IconProfile className="icon" width={24} height={24}/>
              <span className="main-nav__profile-name">{userData?.name}</span>
              <IconArrowBottom
                className="select__arrow icon icon_space--s icon_color--accent select__arrow_view--accent"
                width={38}
                height={38}
              />
            </summary>
            <ul className="select__list">
              <li className="select__item">
                <Link
                  to={AppRoute.PurchaseHistory}
                  className="link link_view--primary"
                >
                  История покупок
                </Link>
              </li>
              <li className="select__item">
                <Link to={AppRoute.Profile} className="link link_view--primary">
                  Профиль
                </Link>
              </li>
              <li className="select__item">
                <button
                  className="button button_view--link"
                  onClick={() => dispatch(showModal(ModalType.Exit))}
                >
                  Выход
                </button>
              </li>
            </ul>
          </details>
        </>}
        {authStatus === AuthStatus.Auth && <button
          className="main-nav__menu-toggle button button_size--s button_behavior--hide-text"
          onClick={() => setIsMobileMenuVisible((isMobileMenuVisible) => !isMobileMenuVisible)}
        >
          <span className="visuallyhidden">Меню</span>
          <svg
            className={cn(
              'icon',
              'icon--menu',
              'icon_color--accent',
              'button_view--burger',
              {'button_view--burger--open': isMobileMenuVisible}
            )}
            width={20}
            height={20}
            viewBox="0 0 26 26"
            strokeLinecap="round"
          >
            <g className="button_view--burger__rects" fill="black">
              <rect
                className="button_view--burger__top-rect"
                width={24}
                height={2}
                x={1}
                y={4}
              />
              <rect
                className="button_view--burger__middle-rect"
                width={24}
                height={2}
                x={1}
                y={12}
              />
              <rect
                className="button_view--burger__bottom-rect"
                width={24}
                height={2}
                x={1}
                y={20}
              />
            </g>
          </svg>
        </button>}
      </nav>
      <ul
        className={cn(
          'main-nav__mobile-menu',
          'container',
          {'main-nav__mobile-menu--open': isMobileMenuVisible}
        )}
      >
        <li>
          <Link
            className="button button_view--primary button_size--s button_size--full"
            to={AppRoute.Profile}
          >
            Каталог
          </Link>
        </li>
        <li>
          <Link
            className="button button_view--primary button_size--s button_size--full"
            to={AppRoute.Blog}
          >
            Блог
          </Link>
        </li>
        <li>
          <Link
            className="button button_view--primary button_size--s button_size--full"
            to={AppRoute.Faq}
          >
            FAQ
          </Link>
        </li>
        <li>
          <Link
            className="button button_view--primary button_size--s button_size--full"
            to={AppRoute.Contacts}
          >
            Контакты
          </Link>
        </li>
        <li>
          <Link
            className="button button_view--primary button_size--s button_size--full"
            to={AppRoute.About}
          >
            О нас
          </Link>
        </li>
        <li>
          <Link
            className="button button_view--tertiary button_size--s button_size--full"
            to={AppRoute.Profile}
          >
            Профиль
          </Link>
        </li>
        <li>
          <Link
            className="button button_view--tertiary button_size--s button_size--full"
            to={AppRoute.Cart}
          >
            Корзина
          </Link>
        </li>
        <li>
          <Link
            className="button button_view--tertiary button_size--s button_size--full"
            to={AppRoute.PurchaseHistory}
          >
            История покупок
          </Link>
        </li>
        <li>
          <button
            className="button button_view--tertiary button_size--s button_size--full"
            onClick={
              () => {
                dispatch(showModal(ModalType.Exit));
                setIsMobileMenuVisible(false);
              }
            }
          >
            Выход
          </button>
        </li>
        {/*<li>*/}
        {/*  <Link*/}
        {/*    className="button button_view--bordered button_size--s button_size--full"*/}
        {/*    to={'tel:+79772507777'}*/}
        {/*  >*/}
        {/*    <IconPhone className="icon" width={20} height={20}/>*/}
        {/*    <span>+7 (977) 250-77-77</span>*/}
        {/*  </Link>*/}
        {/*</li>*/}
      </ul>
    </>
  );
}
