import { ReactComponent as IconArrowLeft } from '../../../sprite/icon-arrow-left.svg';
import { ReactComponent as IconArrowRight } from '../../../sprite/icon-arrow-right.svg';
import cn from 'classnames';
import {ReactElement} from 'react';

//Минимальная длина пагинации - 7, нечетное число
const MOB_PAG_LEN = 5;
const DESK_PAG_LEN = 13;

type SetCurrentPage = (page: number) => void;

type PaginationProps = {
  pageCount: number;
  currentPage: number;
  setCurrentPage: SetCurrentPage;
}

const createPaginationItem = (count: number, isActive: boolean, setCurrentPage: SetCurrentPage) => (
  <li
    className={cn(
      'pagination__item',
       {'pagination__item--current': isActive}
    )}
    onClick={() => setCurrentPage(count)}
    key={count}
  >
    <a className="link link_view--primary">
      {count}
    </a>
  </li>
);

const getTrimMode = (pageCount: number, pagLen: number, activePageNum: number) => {
  if (!(pageCount && pagLen && activePageNum)) {
    throw 'Не переданы все параметры';
  }

  const middleCount = (pagLen + 1) / 2;

  const isShort = pageCount <= pagLen;

  return {
    isTrimStart: !isShort && activePageNum > middleCount,
    isTrimEnd: !isShort && (pageCount - activePageNum) >= middleCount,
  };
}

const getPaginationInterval = (pageCount: number, pagLen: number, activePageNum: number) => {
  const middleCount = (pagLen + 1) / 2;

  const isShort = pageCount <= pagLen;

  const {isTrimStart, isTrimEnd} = getTrimMode(pageCount, pagLen, activePageNum);

  if (isShort) {
    return {
      from: 2,
      to: pageCount - 1,
    }
  } else if (isTrimEnd && !isTrimStart) {
    return {
      from: 2,
      to: pagLen - 2,
    }
  } else if (!isTrimEnd && isTrimStart) {
    return {
      from: pageCount - middleCount,
      to: pageCount - 1,
    }
  }

  return {
    from: activePageNum - middleCount + 3,
    to: activePageNum + middleCount - 3,
  }
}

const createPaginationMiddleItems = (pageCount: number, pagLen: number, activePageNum: number, setCurrentPage: SetCurrentPage) => {
  const {from, to} = getPaginationInterval(pageCount, pagLen, activePageNum);

  const paginationItem = [];

  for (let i = from; i <= to; i++) {
    paginationItem.push(createPaginationItem(i, activePageNum === i, setCurrentPage));
  }

  return <>
    {paginationItem}
  </>;
}

export default function Pagination({pageCount, currentPage, setCurrentPage}: PaginationProps): ReactElement {
  if (pageCount <= 1) {
    return <></>;
  }

  const pagLen = (window.innerWidth <= 720) ? MOB_PAG_LEN : DESK_PAG_LEN;

  const {isTrimStart, isTrimEnd} = getTrimMode(pageCount, pagLen, currentPage);

  return (
    <nav
      aria-label="pagination"
      className={cn(
        'catalog__pagination',
        'pagination',
        {'pagination--trim-start': isTrimStart},
        {'pagination--trim-end': isTrimEnd},
      )}
    >
      <ol className="pagination__list">
        <li className="pagination__item pagination__item--nav">
          <button
            className="button pagination__item--nav-prev"
            disabled={currentPage === 1}
            onClick={() => {
              if (currentPage === 1) {
                return;
              }
              setCurrentPage(currentPage - 1);
            }}
          >
            <IconArrowLeft className="icon radius--8" width="32" height="32"/>
            <span className="visuallyhidden">Предыдущая страница</span>
          </button>
        </li>

        {pageCount >= 2 && createPaginationItem(1, currentPage === 1, setCurrentPage)}

        <li className="pagination__item pagination__item-ellipsis pagination__item-ellipsis--start">
          <a>
            &#8230;
          </a>
        </li>

        {createPaginationMiddleItems(pageCount, pagLen, currentPage, setCurrentPage)}

        <li className="pagination__item pagination__item-ellipsis pagination__item-ellipsis--end">
          <a>
            &#8230;
          </a>
        </li>

        {pageCount >= 2 ? createPaginationItem(pageCount, currentPage === pageCount, setCurrentPage) : ''}

        <li className="pagination__item pagination__item--nav">
          <button
            className="button pagination__item--nav-next"
            disabled={currentPage === pageCount}
            onClick={() => {
              if (currentPage === pageCount) {
                return;
              }
              setCurrentPage(currentPage + 1);
            }}
          >
            <IconArrowRight className="icon radius--8" width="32" height="32"/>
            <span className="visuallyhidden">Следующая страница</span>
          </button>
        </li>
      </ol>
    </nav>
  );
}
