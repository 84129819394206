export enum AppRoute {
  Root = '/',
  Catalog = '/katalog/',
  CatalogType = '/katalog/:type/',
  CatalogCategory = '/katalog/:type/:category/',
  Goods = '/products/:id/',
  Blog = '/blog/',
  Article = '/blog/:slug/',
  Faq = '/chasto-zadavaemye-voprosy/',
  Contacts = '/kontakty/',
  About = '/o-nas/',

  Cart = '/korzina/',
  Profile = '/profil/',
  PurchaseHistory = '/istoriya-pokupok/',

  Privacy = '/politika-konfidencialnosti/',

  Search = '/poisk/',

  Error = '/404/',

  AuthEmail = '/auth/',
  ResetPassword = '/auth/reset-password/',
  Login = '/#login',
}

export enum AuthStatus {
  Auth = 'AUTH',
  NoAuth = 'NO_AUTH',
  Unknown = 'UNKNOWN',
}

export enum APIRoute {
  Register = '/register',
  Login = '/login',
  Verify = '/email/verify',
  UserInfo = '/me',
  Logout = '/logout',
  Goods = '/catalog',
  Reset = '/forgot-password',
  ConfirmReset = '/reset-password',
  Blog = '/articles',
  Edit = '/me/edit',
  FilterFields = '/catalog/filters',
}

export const RANGE_STEP = 1000;
