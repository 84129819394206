import React, {ReactElement} from 'react';
import SearchList from './search-list/search-list';

export default function Search(): ReactElement {
  return (
    <main className="profile__main-page">
      <h1 className="search__title title title_size--l container">Результат поиска:</h1>

      <section className="search container">
        <SearchList/>
      </section>
    </main>
  )
}
