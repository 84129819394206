import {Good} from '../types/goods';
import {SortingType} from '../types/sorting';

export const sortGoods = (goods: Good[], sortingType: SortingType): Good[] => {
  const sortedGoods = goods.slice();

  switch (sortingType) {
    case SortingType.Popular:
      return sortedGoods.sort((a, b) => -compareGoodsView(a, b));
    case SortingType.DateOlder:
      return sortedGoods.sort((a, b) => compareGoodsDate(a, b));
    case SortingType.DateNewer:
      return sortedGoods.sort((a, b) => -compareGoodsDate(a, b));
    case SortingType.PriceLower:
      return sortedGoods.sort((a, b) => compareGoodsPrice(a, b));
    case SortingType.PriceHigher:
      return sortedGoods.sort((a, b) => -compareGoodsPrice(a, b));
  }
}

const compareGoodsView = (goodsA: Good, goodsB: Good): number => (
  goodsA.view - goodsB.view
);

const compareGoodsDate = (goodsA: Good, goodsB: Good): number => {
  const dateA = new Date(goodsA.date).getTime();
  const dateB = new Date(goodsB.date).getTime();

  return dateA - dateB;
};

const compareGoodsPrice = (goodsA: Good, goodsB: Good): number => (
  goodsA.price - goodsB.price
);
