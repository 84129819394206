import React, {ReactElement} from 'react';
import Header from '../../components/page/header/header';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import Breadcrumbs from '../../components/page/breadcrumbs/breadcrumbs';
import Plug from '../../components/plug/plug';
import PlugReturn from '../../components/plug/plug-return/plug-return';
import {AppRoute} from '../../const/common';
import {Helmet} from 'react-helmet-async';
import {CanonicalUrl, PageDesc, PageTitle} from '../../const/seo';
import Seo from '../../components/service/seo/seo';

export default function NotFoundScreen(): ReactElement {
  return (
    <>
      <Seo
        title={PageTitle.Error}
        desc={PageDesc.Main}
      />

      <Header/>
      <Breadcrumbs
        firstPage={'404'}
      />
      <main className="error-page">
        <h1 className="error-page__title title title_size--l container">404</h1>
        <Plug
          caption={'Страница не найдена'}
          className={'container'}
        >
          <PlugReturn
            caption={'Вернуться на главную'}
            link={AppRoute.Root}
          />
        </Plug>
      </main>
      <Footer/>
      <ModalContainer />
    </>
  );
}
