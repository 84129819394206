import {ReactElement} from 'react';
import Logo from '../logo/logo';
import {Link} from 'react-router-dom';
import {AppRoute} from '../../../const/common';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import { ReactComponent as IconEmail } from '../../../sprite/icon-email.svg';
import { ReactComponent as IconTelegram } from '../../../sprite/icon-tg.svg';
import { ReactComponent as IconWhatsapp } from '../../../sprite/icon-whatsapp.svg';
import {ExternalLinks} from '../../../const/seo';
import {getCategoryList} from '../../../utils/category';
import {GoodType, GoodTypeName} from '../../../types/goods';

type categoryListProps = {
  [slug: string]: string,
};

const createCategoryList = (categoryName: GoodType, categoryList: categoryListProps, key: string): ReactElement => {
  return (
    <ul className="main-footer__list">
      <li>
        <h3>
          <Link to={`${AppRoute.Catalog}${GoodTypeName[categoryName].slug}`} className="link link_view--primary text_weight--700">
            {GoodTypeName[categoryName].name}
          </Link>
        </h3>
      </li>
      {Object.entries(categoryList).map(([slug, name]) => {
        return (
          <li>
            <Link to={`${AppRoute.Catalog}${GoodTypeName[categoryName].slug}/${slug}`} className="link link_view--primary" key={slug}>
              {name}
            </Link>
          </li>
        );
      })}
    </ul>
  )
}

export default function Footer(): ReactElement {
  const goods = useAppSelector((state) => state.goods);

  const goodsCategory = getCategoryList(goods);

  return (
    <footer className="main-footer">
      <div className="main-footer__inner container text_size--14">
        <div className="main-footer__about">
          <Logo isLight={true}/>
          <p className="text_view--tertiary text_size--12">
            Реализуем крутые ТЗ для вашего IT проекта.
            <br/>
            Индивидуальные техзадания и на заказ.
          </p>
        </div>
        <div className="main-footer__cell main-footer__catalog">
          <h3 className="main-footer__title text_weight--700">Каталог</h3>

          <div className="main-footer__catalog-inner grid grid_col--3">
            {
              Object.values(GoodType).map((type) => {
                if (Object.keys(goodsCategory[type]).length === 0) {
                  return;
                }

                return createCategoryList(type, goodsCategory[type], `category-${type}`)
              })
            }
          </div>
        </div>
        <div className="main-footer__cell">
          <h3 className="main-footer__title text_weight--700">О нас</h3>

          <ul className="main-footer__list main-footer__list-about">
            <li>
              <Link
                className="link link_view--primary link_view--hover-opacity"
                to={AppRoute.Blog}
              >
                Блог
              </Link>
            </li>
            <li>
              <Link
                className="link link_view--primary link_view--hover-opacity"
                to={AppRoute.Blog}
              >
                Оплата и доставка
              </Link>
            </li>
            <li>
              <Link
                className="link link_view--primary link_view--hover-opacity"
                to={AppRoute.Blog}
              >
                Контакты
              </Link>
            </li>
            <li>
              <Link
                className="link link_view--primary link_view--hover-opacity"
                to={AppRoute.Faq}
              >
                FAQ
              </Link>
            </li>
            <li>
              <Link
                className="link link_view--primary link_view--hover-opacity"
                to={AppRoute.About}
              >
                О нас
              </Link>
            </li>
          </ul>

          <h3 className="main-footer__title text_weight--700">Контакты</h3>
          <ul className="main-footer__list">
            <li>
              <a
                className="link link_view--primary link_view--hover-underline"
                href={ExternalLinks.InfoEmail}
              >
                <IconEmail className="icon" width={20} height={20}/>
                info@it-tz.ru
              </a>
            </li>
            {/*<li className="main-footer__networks">*/}
            {/*  <a className="link link_view--primary" href={ExternalLinks.Telegram}>*/}
            {/*    <IconTelegram className="icon" width={32} height={32}/>*/}
            {/*    <span className="visuallyhidden">Телеграм</span>*/}
            {/*  </a>*/}
            {/*  <a className="link link_view--primary" href={ExternalLinks.WhatsApp}>*/}
            {/*    <IconWhatsapp className="icon" width={32} height={32}/>*/}
            {/*    <span className="visuallyhidden">WhatsApp</span>*/}
            {/*  </a>*/}
            {/*</li>*/}
          </ul>
        </div>
      </div>
      <div className="main-footer__copy text_view--tertiary text_size--14">
        <div className="main-footer__copy-inner container">
          <p>
            Task Store ©{new Date(Date.now()).getFullYear()}{' '}<span>Все права защищены</span>
          </p>
          <p>
            <Link
              className="link link_view--underline"
              to={AppRoute.Privacy}
            >
              Политика конфиденциальности
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}
