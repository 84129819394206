import {Good, GoodType} from '../types/goods';
import {Filter} from '../types/filter';

export const filterGoods = (goods: Good[], filter: Filter, currentType: GoodType | null, category: string | null): Good[] => {
  const filteredTypeGoods = currentType
    ? goods.filter((good) => good.type === currentType)
    : goods.slice();

  const filteredCategoryGoods = category
    ? filteredTypeGoods.filter((good) => good.category.slug === category)
    : filteredTypeGoods.slice();

  const filteredPriceGoods = filteredCategoryGoods.filter((good) => good.price >= filter.price[0] && good.price <= filter.price[1]);

  const filteredGraphicGoods = filter.graphics.length !== 0
    ? filteredPriceGoods.filter((good) => good.graphic && filter.graphics.includes(good.graphic))
    : filteredPriceGoods.slice();

  const filteredPlatformGoods = filter.platforms.length !== 0
    ? filteredGraphicGoods.filter((good) => good.platform && filter.platforms.includes(good.platform))
    : filteredGraphicGoods.slice();

  const filteredGenreGoods = filter.genres.length !== 0
    ? filteredPlatformGoods.filter((good) => {
      return filter.genres.map((genre) => genre.value).includes(good.genre)
    })
    : filteredPlatformGoods.slice();

  return filteredGenreGoods;
}

export const getMinPrice = (goods: Good[]) => {
  return Math.min(...goods.map((good) => good.price));
}

export const getMaxPrice = (goods: Good[]) => {
  return Math.max(...goods.map((good) => good.price));
}
