import React, {ReactElement, useEffect, useState} from 'react';
import {useAppDispatch} from '../../hooks';
import {useParams} from 'react-router-dom';
import {changeCurrentCategory, changeCurrentType} from '../../store/actions';
import Header from '../../components/page/header/header';
import GoodsPopup from '../../components/goods/goods-popup/goods-popup';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import Catalog from '../../components/catalog/catalog';
import {GoodType, GoodTypeName} from '../../types/goods';

export default function CatalogScreen(): ReactElement {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const params = useParams();

  useEffect(() => {
    setCurrentPage(3);
  }, [params]);

  useEffect(() => {
    if (params.type) {
      const currentType = Object.values(GoodType).find((type) => {
        return GoodTypeName[type].slug === params.type;
      });

      if (currentType) {
        dispatch(changeCurrentType(currentType));
      }
    }

    if (params.category) {
      dispatch(changeCurrentCategory(params.category));
    }

    if (!params.id) {
      !params.category && dispatch(changeCurrentCategory(null));
      !params.type && dispatch(changeCurrentType(null));
    }
  }, [params]);


  return (
    <>
      <Header />
      <Catalog />
      {params.id && <GoodsPopup />}
      <Footer />
      <ModalContainer />
    </>
  );
}
