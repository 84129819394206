import React, {useEffect, useState} from 'react';
import PromoSlide from './promo-slide/promo-slide';
import {GoodType, PlatformType} from '../../types/goods';

export default function Promo() {
  const [bannerStep, setBannerStep] = useState(1);

  useEffect(() => {
    const id = setTimeout(() => {
      setBannerStep((bannerStep) % 3 + 1);
    }, 4000)

    return () => clearTimeout(id);
  }, [bannerStep]);

  return (
    <section
      className="promo space_padding-xxl space_padding-sides--empty"
      data-promo-banner={bannerStep}
    >
      <div className="promo__inner container">
        <ul className="promo__list">
          {
            <PromoSlide
              title={'Построй свой город'}
              subtitle={'Градостроительный симулятор'}
              type={GoodType.Game}
              genre={'Градостроительная стратегия'}
              platform={PlatformType.Mobile}
              desc={'Градостроительный симулятор с уникальной графикой и детализированной анимацией строительных работ. Создавай и развивай города, заключай тесные экономические связи'}
              id={'19'}
              slideStep={1}
              sale={10}
            />
          }
          {
            <PromoSlide
              title={'Прокачай себя'}
              subtitle={'Приложение с тренировками'}
              type={GoodType.App}
              genre={'Фитнес'}
              platform={PlatformType.Mobile}
              desc={'Приложение с готовыми тренировочными программами по различным направлениям для всех уровней подготовки. Оно буквально станет для пользователя его карманным тренером.'}
              id={'16'}
              slideStep={2}
              sale={20}
            />
          }
          {
            <PromoSlide
              title={'Ваше портфолио'}
              subtitle={'Сайт с галереей проектов'}
              type={GoodType.Site}
              genre={'Сайт с галереей проектов'}
              platform={PlatformType.Web}
              desc={'Красивая демонстрация всех реализованных проектов в удобном кабинете с единой учетной записью. Платформа для публикации новостей разработки и получения обратной связи.'}
              id={'23'}
              slideStep={3}
              sale={30}
            />
          }
        </ul>
      </div>
      <ul className="promo__slider-bullet-list">
        <li className="promo__slider-bullet-item">
          <button
            data-promo-bullet={1}
            onClick={() => setBannerStep(1)}
          >
            <span className="visuallyhidden">1-й слайд</span>
          </button>
        </li>
        <li className="promo__slider-bullet-item">
          <button
            data-promo-bullet={2}
            onClick={() => setBannerStep(2)}
          >
            <span className="visuallyhidden">2-й слайд</span>
          </button>
        </li>
        <li className="promo__slider-bullet-item">
          <button
            data-promo-bullet={3}
            onClick={() => setBannerStep(3)}
          >
            <span className="visuallyhidden">3-й слайд</span>
          </button>
        </li>
      </ul>
    </section>
  );
}
