import React, {ReactElement} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AppRoute} from '../../const/common';
import MainScreen from '../../pages/main-screen/main-screen';
import PrivacyScreen from '../../pages/privacy-screen/privacy-screen';
import PrivateRoute from '../service/private-route/private-route';
import ProfileScreen from '../../pages/profile-screen/profile-screen';
import {useAppSelector} from '../../hooks';
import ScrollToTop from '../service/scroll-top/scroll-top';
import NotFoundScreen from '../../pages/not-found-screen/not-found-screen';
import PurchaseHistoryScreen from '../../pages/purchase-history-screen/purchase-history-screen';
import {HelmetProvider} from 'react-helmet-async';
import SearchScreen from '../../pages/search-screen/search-screen';
import ArticleScreen from '../../pages/article-screen/article-screen';
import Verify from '../service/verify/verify';
import Reset from '../service/reset/reset';
import BlogScreen from '../../pages/blog-screen/blog-screen';
import CatalogScreen from '../../pages/catalog-screen/catalog-screen';
import CartScreen from '../../pages/cart-screen/cart-screen';
import FaqScreen from '../../pages/faq-screen/faq-screen';
import ContactsScreen from '../../pages/contacts-screen/contacts-screen';
import AboutScreen from '../../pages/about-screen/about-screen';

export default function App(): ReactElement {
  const authStatus = useAppSelector((state) => state.authStatus);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path={AppRoute.Root}
            element={
              <>
                <ScrollToTop />
                <MainScreen />
              </>
            }
          />

          <Route
            path={AppRoute.Catalog}
            element={
              <>
                <ScrollToTop />
                <CatalogScreen />
              </>
            }
          />

          <Route
            path={AppRoute.CatalogType}
            element={
              <>
                <ScrollToTop />
                <CatalogScreen />
              </>
            }
          />

          <Route
            path={AppRoute.CatalogCategory}
            element={
              <>
                <ScrollToTop />
                <CatalogScreen />
              </>
            }
          />

          <Route
            path={AppRoute.Goods}
            element={
              <CatalogScreen />
            }
          />
          <Route
            path={AppRoute.Privacy}
            element={
              <>
                <ScrollToTop />
                <PrivacyScreen />
              </>
            }
          />
          <Route
            path={AppRoute.PurchaseHistory}
            element={
              <PrivateRoute authStatus = {authStatus}>
                <>
                  <ScrollToTop />
                  <PurchaseHistoryScreen />
                </>
              </PrivateRoute>
            }
          />
          <Route
            path = {AppRoute.Profile}
            element = {
              <PrivateRoute authStatus = {authStatus}>
                <>
                  <ScrollToTop />
                  <ProfileScreen/>
                </>
              </PrivateRoute>
            }
          />

          <Route
            path={AppRoute.Search}
            element={
              <>
                <ScrollToTop />
                <SearchScreen />
              </>
            }
          />

          <Route
            path={AppRoute.Cart}
            element={
              <>
                <ScrollToTop />
                <CartScreen />
              </>
            }
          />

          <Route
            path={'*'}
            element={
              <>
                <ScrollToTop />
                <NotFoundScreen />
              </>
            }

          />

          <Route
            path={AppRoute.Blog}
            element={
              <>
                <ScrollToTop />
                <BlogScreen />
              </>
            }
          />

          <Route
            path={AppRoute.Article}
            element={
              <>
                <ScrollToTop />
                <ArticleScreen />
              </>
            }
          />

          <Route
            path={AppRoute.AuthEmail}
            element={
              <>
                <ScrollToTop />
                <Verify />
                <MainScreen />
              </>
            }
          />

          <Route
            path={AppRoute.ResetPassword}
            element={
              <>
                <ScrollToTop />
                <Reset />
                <MainScreen />
              </>
            }
          />

          <Route
            path={AppRoute.Faq}
            element={
              <>
                <ScrollToTop />
                <FaqScreen />
              </>
            }
          />

          <Route
            path={AppRoute.Contacts}
            element={
              <>
                <ScrollToTop />
                <ContactsScreen />
              </>
            }
          />

          <Route
            path={AppRoute.About}
            element={
              <>
                <ScrollToTop />
                <AboutScreen />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}
