import React, {ReactElement, useState} from 'react';
import {useAppDispatch} from '../../../hooks';
import Modal from '../modal';
import FormTitle from '../../atomas/form-title/form-title';
import FormSubmitButton from '../../atomas/form-submit-button/form-submit-button';
import {hideModal, showModal} from '../../../store/actions';
import InputPassword from '../../atomas/input-password/input-password';
import {ValidationConfig} from '../../../const/validation-config';
import FormCloseButton from '../../atomas/form-close-button/form-close-button';
import {FormProvider, useForm} from 'react-hook-form';
import {ConfirmResetData, EditPasswordNewData, EditPasswordOldData} from '../../../types/data';
import {ModalType} from '../../../types/modalType';
import {editPasswordAction} from '../../../store/api-actions';

const FORM_NAME = 'edit-password';

export default function ModalEditPassword(): ReactElement {
  const methodsFirstStep = useForm<EditPasswordOldData>();
  const methodsSecondStep = useForm<EditPasswordNewData>();
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(1);
  const [currentPassword, setCurrentPassword] = useState('');

  const onFirstStepSubmit = methodsFirstStep.handleSubmit(data => {
    setCurrentPassword(data.current_password);
    setStep(2);
  });

  const onSecondStepSubmit = methodsSecondStep.handleSubmit(data => {
    data.current_password = currentPassword;

    dispatch(editPasswordAction(data));
  });

  return (
    <>
      {step === 1 ? <Modal>
        <FormProvider {...methodsFirstStep}>
          <form
            className="modal__inner"
            spellCheck="false"
            noValidate
            onSubmit={event => event.preventDefault()}
          >
            <FormTitle>Изменение пароля</FormTitle>

            <p
              className="input__wrapper input__wrapper--field text_view--tertiary space_bottom-l text_size--14 modal__desc">
              Сначала введите свой старый пароль
            </p>

            <InputPassword
              formName={FORM_NAME}
              name={'current_password'}
              label={'Пароль'}
              placeholder={'Введите ваш пароль'}
              validation={ValidationConfig.password}
              key={'current_password'}
            />

            <button
              className="button button_view--link button_view--link-underline text_view--accent space_bottom-s text_size--14 text_line-height--140"
              type="button"
              onClick={() => dispatch(showModal(ModalType.SendCode))}
            >
              Забыли пароль?
            </button>

            <FormSubmitButton onSubmit={onFirstStepSubmit}>
              Продолжить
            </FormSubmitButton>

            <FormCloseButton onClick={() => dispatch(hideModal())}/>
          </form>
        </FormProvider>
      </Modal> : <Modal>
        <FormProvider {...methodsSecondStep}>
          <form
            className="modal__inner"
            spellCheck="false"
            noValidate
            onSubmit={event => event.preventDefault()}
          >
            <FormTitle>Изменение пароля</FormTitle>

            <p
              className="input__wrapper input__wrapper--field text_view--tertiary space_bottom-l text_size--14 modal__desc">
              Введите ваш новый пароль и подтвердите его
            </p>

            <InputPassword
              formName={FORM_NAME}
              name={'password'}
              label={'Пароль'}
              placeholder={'Введите ваш пароль'}
              validation={ValidationConfig.password}
              key={'password'}
            />

            <InputPassword
              formName={FORM_NAME}
              name={'password_confirmation'}
              label={'Повторите пароль'}
              placeholder={'Введите ваш пароль'}
              validation={ValidationConfig.password}
              isConfirmPassword={true}
              key={'password_confirmation'}
            />

            <FormSubmitButton onSubmit={onSecondStepSubmit}>
              Сохранить
            </FormSubmitButton>

            <FormCloseButton onClick={() => dispatch(hideModal())}/>
          </form>
        </FormProvider>
      </Modal>}
    </>
  );
}
