import GoodsCard from '../../goods/goods-card/goods-card';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {filterGoods} from '../../../utils/filter';
import {sortGoods} from '../../../utils/sorting';
import Pagination from '../../page/pagination/pagination';
import {useEffect, useRef, useState} from 'react';
import {ScrollPosition, scrollTop} from '../../../utils/common';
import Plug from '../../plug/plug';
import PlugAction from '../../plug/plug-action/plug-action';
import {resetFilter} from '../../../store/actions';
import {useParams} from 'react-router-dom';
import {Good} from '../../../types/goods';

const AD_PER_PAGE = 30;

type CatalogListProps = {
  filteredGoods: Good[];
}

export default function CatalogList({filteredGoods}: CatalogListProps) {
  const dispatch = useAppDispatch();

  const isInitialMount = useRef(true);

  const [currentPage, setCurrentPage] = useState(1);

  const filter = useAppSelector((state) => state.filter);
  const sorting = useAppSelector((state) => state.sorting);

  const sortedGoods = sortGoods(filteredGoods, sorting);

  const pageCount = Math.ceil(sortedGoods.length / AD_PER_PAGE);

  const pageGoods = sortedGoods.slice(
    (currentPage - 1) * AD_PER_PAGE,
    currentPage * AD_PER_PAGE,
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      scrollTop(ScrollPosition.Catalog);
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  return (
    <>
      <ul className="catalog__list">
        {pageGoods.map((good) => (
          <GoodsCard
            title={good.desc}
            id={good.id}
            view={good.view}
            purchases={good.purchases}
            genre={good.genre}
            nameGenre={good.nameGenre}
            price={good.price}
            oldPrice={good.oldPrice}
            images={good.images}
            graphic={good.graphic}
            platform={good.platform}
            key={good.id}
          ></GoodsCard>
        ))}

        {pageGoods.length === 0 && <Plug
          caption={'Нет подходящий товаров'}
        >
          <PlugAction
            caption={'Сбросить фильтры'}
            onClick={() => dispatch(resetFilter())}
          />
        </Plug>}
      </ul>

      <Pagination
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={(page) => setCurrentPage(page)}
      />
    </>
  );
}
