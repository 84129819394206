import React, {ReactElement} from 'react';

const getNavItemForSection = (section: Element) => {
  const sectionId = section.id;

  return document.querySelector(`[href="#${sectionId}"]`);
};

const activeNavItem = (link: Element) => {
  const navItemNode = document.querySelector('.privacy__nav-item--active');

  if (!navItemNode) {
    return;
  }

  navItemNode.classList.remove('privacy__nav-item--active');

  const item = link.closest('.privacy__nav-item');

  if (!item) {
    return;
  }

  item.classList.add('privacy__nav-item--active');
}

const checkSection = (section: Element) => {
  const sectionTopPosition = section.getBoundingClientRect().top;
  const sectionBottomPosition = section.getBoundingClientRect().top + section.getBoundingClientRect().height;

  const windowMiddlePosition = window.innerHeight / 2

  if (sectionTopPosition < windowMiddlePosition && sectionBottomPosition > windowMiddlePosition) {
    const currentItem = getNavItemForSection(section);
    currentItem && activeNavItem(currentItem);
  }
};

const pageScrollHandler = () => {
  const sections = document.querySelectorAll('.privacy__section');
  sections.forEach((section) => {
    checkSection(section);
  });
}

export default function Privacy(): ReactElement {
  React.useEffect(() => {
    window.addEventListener('scroll', pageScrollHandler);

    return () => {
      window.removeEventListener('scroll', pageScrollHandler);
    };
  }, []);

  return (
    <main className="privacy__main-page">
      <h1 className="privacy__title title title_size--l container">
        Политика конфиденциальности
      </h1>
      <section className="privacy container">
        <nav className="privacy__nav radius--16 border_view--primary">
          <ul className="privacy__nav-list text_size--14">
            <li className="privacy__nav-item privacy__nav-item--active">
              <a className="link link_view--primary" href="#user">
                Условия пользовательского соглашения
              </a>
            </li>
            <li className="privacy__nav-item">
              <a className="link link_view--primary" href="#site">
                Условия использования сайта
              </a>
            </li>
            <li className="privacy__nav-item">
              <a className="link link_view--primary" href="#license">
                Лицензия на использование Сайта
              </a>
            </li>
            <li className="privacy__nav-item">
              <a className="link link_view--primary" href="#guaranty">
                Гарантии в пользовательском соглашении
              </a>
            </li>
            <li className="privacy__nav-item">
              <a className="link link_view--primary" href="#notify">
                Уведомления и рассылка
              </a>
            </li>
          </ul>
        </nav>
        <section
          id="user"
          className="privacy__section border_view--primary text_size--14 text_line-height--150 space_bottom-xl"
        >
          <h2 className="title title_size--m space_bottom-l">
            Общие условия пользовательского соглашения
          </h2>
          <p className="privacy__text">
            В настоящем документе и вытекающих или связанным с ним отношениях Сторон
            применяются следующие термины и определения:
          </p>
          <p className="privacy__text">
            а) Платформа — программно-аппаратные средства, интегрированные с Сайтом
            Администрации;
          </p>
          <p className="privacy__text">
            б) Пользователь — дееспособное физическое лицо, присоединившееся к
            настоящему Соглашению в собственном интересе либо выступающее от имени и
            в интересах представляемого им юридического лица.
          </p>
          <p className="privacy__text">
            в) Сайт Администрации/ Сайт — интернет-сайты, размещенные в домене
            https://it-tz.ru/ источник и его поддоменах.
          </p>
          <p className="privacy__text">
            г) Сервис — комплекс услуг и лицензия, предоставляемые Пользователю с
            использованием Платформы.
          </p>
          <p className="privacy__text">
            д) Соглашение - настоящее соглашение со всеми дополнениями и
            изменениями. Готовое решение для вашего бизнеса
          </p>
          <p className="privacy__text">
            1.2. Использование вами Сервиса любым способом и в любой форме в
            пределах его объявленных функциональных возможностей, включая:
          </p>
          <p className="privacy__text">
            просмотр размещенных на Сайте материалов;
            <br />
            регистрация и/или авторизация на Сайте,
            <br />
            размещение или отображение на Сайте любых материалов, включая но не
            ограничиваясь такими как: тексты, гипертекстовые ссылки, изображения,
            аудио и видео- файлы, сведения и/или иная информация,
            <br />
            создает договор на условиях настоящего Соглашения в соответствии с
            положениями ст.437 и 438 Гражданского кодекса Российской Федерации.
          </p>
          <p className="privacy__text">
            1.3. Воспользовавшись любой из указанных выше возможностей по
            использованию Сервиса вы подтверждаете, что:
          </p>
          <p className="privacy__text">
            а) Ознакомились с условиями настоящего Соглашения в полном объеме до
            начала использования Сервиса.
          </p>
          <p className="privacy__text">
            б) Принимаете все условия настоящего Соглашения в полном объеме без
            каких-либо изъятий и ограничений с вашей стороны и обязуетесь их
            соблюдать или прекратить использование Сервиса. Если вы не согласны с
            условиями настоящего Соглашения или не имеете права на заключение
            договора на их основе, вам следует незамедлительно прекратить любое
            использование Сервиса.
          </p>
          <p className="privacy__text">
            в) Соглашение (в том числе любая из его частей) может быть изменено
            Администрацией без какого-либо специального уведомления. Новая редакция
            Соглашения вступает в силу с момента ее размещения на Сайте
            Администрации либо доведения до сведения Пользователя в иной удобной
            форме, если иное не предусмотрено новой редакцией Соглашения.
          </p>
        </section>
        <section
          id="site"
          className="privacy__section border_view--primary text_size--14 text_line-height--150 space_bottom-xl"
        >
          <h2 className="title title_size--m space_bottom-l">
            Условия использования сайта
          </h2>
          <p className="privacy__text">
            2.1. Использование функциональных возможностей Сервиса допускается
            только после прохождения Пользователем регистрации и авторизации на
            Сайте в соответствии с установленной Администрацией процедурой.
          </p>
          <p className="privacy__text">
            2.2. Технические, организационные и коммерческие условия использования
            Сервиса, в том числе его функциональных возможностей доводятся до
            сведения Пользователей путем отдельного размещения на Сайте или путем
            нотификации Пользователей.
          </p>
          <p className="privacy__text">
            2.3. Выбранные Пользователем логин и пароль являются необходимой и
            достаточной информацией для доступа Пользователя на Сайт. Пользователь
            не имеет права передавать свои логин и пароль третьим лицам, несет
            полную ответственность за их сохранность, самостоятельно выбирая способ
            их хранения.
          </p>
        </section>
        <section
          id="license"
          className="privacy__section border_view--primary text_size--14 text_line-height--150 space_bottom-xl"
        >
          <h2 className="title title_size--m space_bottom-l">
            Лицензия на использование Сайта
          </h2>
          <p className="privacy__text">
            2.1. Использование функциональных возможностей Сервиса допускается
            только после прохождения Пользователем регистрации и авторизации на
            Сайте в соответствии с установленной Администрацией процедурой.
          </p>
          <p className="privacy__text">
            2.2. Технические, организационные и коммерческие условия использования
            Сервиса, в том числе его функциональных возможностей доводятся до
            сведения Пользователей путем отдельного размещения на Сайте или путем
            нотификации Пользователей.
          </p>
          <p className="privacy__text">
            2.3. Выбранные Пользователем логин и пароль являются необходимой и
            достаточной информацией для доступа Пользователя на Сайт. Пользователь
            не имеет права передавать свои логин и пароль третьим лицам, несет
            полную ответственность за их сохранность, самостоятельно выбирая способ
            их хранения.
          </p>
        </section>
        <section
          id="guaranty"
          className="privacy__section border_view--primary text_size--14 text_line-height--150 space_bottom-xl"
        >
          <h2 className="title title_size--m space_bottom-l">
            Гарантии в пользовательском соглашении
          </h2>
          <p className="privacy__text">
            2.1. Использование функциональных возможностей Сервиса допускается
            только после прохождения Пользователем регистрации и авторизации на
            Сайте в соответствии с установленной Администрацией процедурой.
          </p>
          <p className="privacy__text">
            2.2. Технические, организационные и коммерческие условия использования
            Сервиса, в том числе его функциональных возможностей доводятся до
            сведения Пользователей путем отдельного размещения на Сайте или путем
            нотификации Пользователей.
          </p>
          <p className="privacy__text">
            2.3. Выбранные Пользователем логин и пароль являются необходимой и
            достаточной информацией для доступа Пользователя на Сайт. Пользователь
            не имеет права передавать свои логин и пароль третьим лицам, несет
            полную ответственность за их сохранность, самостоятельно выбирая способ
            их хранения.
          </p>
        </section>
        <section
          id="notify"
          className="privacy__section border_view--primary text_size--14 text_line-height--150"
        >
          <h2 className="title title_size--m space_bottom-l">
            Уведомления и рассылка
          </h2>
          <p className="privacy__text">
            2.1. Использование функциональных возможностей Сервиса допускается
            только после прохождения Пользователем регистрации и авторизации на
            Сайте в соответствии с установленной Администрацией процедурой.
          </p>
          <p className="privacy__text">
            2.2. Технические, организационные и коммерческие условия использования
            Сервиса, в том числе его функциональных возможностей доводятся до
            сведения Пользователей путем отдельного размещения на Сайте или путем
            нотификации Пользователей.
          </p>
          <p className="privacy__text">
            2.3. Выбранные Пользователем логин и пароль являются необходимой и
            достаточной информацией для доступа Пользователя на Сайт. Пользователь
            не имеет права передавать свои логин и пароль третьим лицам, несет
            полную ответственность за их сохранность, самостоятельно выбирая способ
            их хранения.
          </p>
        </section>
      </section>
    </main>

  );
}
