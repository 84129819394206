import {Genre} from '../types/filter';

export const toggleArrayItem = <T>(array: T[], currentItem: T): T[] => {
  const pos = array.findIndex((item) => item === currentItem);

  return pos === -1
    ? [...array, currentItem]
    : [...array.slice(0, pos), ...array.slice(pos + 1)];
};

export const toggleGenresArrayItem = (genres: Genre[], currentGenre: Genre): Genre[] => {
  const pos = genres.findIndex((genre) => genre.value === currentGenre.value);

  return pos === -1
    ? [...genres, currentGenre]
    : [...genres.slice(0, pos), ...genres.slice(pos + 1)];
}
