import {ReactElement} from 'react';

type PlugActionProps = {
  caption: string;
  onClick: () => void;
}

export default function PlugAction({caption, onClick}: PlugActionProps): ReactElement {
  return (
    <button
      onClick={() => onClick()}
      type='button'
      className="empty-plug__action button button_view--primary button_size--s"
    >
      {caption}
    </button>
  )
}
