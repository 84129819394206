import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {setPreventScroll} from '../../../store/actions';

export default function ScrollToTop() {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const isPreventScroll = useAppSelector((state) => state.isPreventScroll);

  useEffect(() => {
    !isPreventScroll && window.scrollTo(0, 0);
  }, [pathname]);

  if (isPreventScroll) {
    dispatch(setPreventScroll(false));
    return null;
  }

  return null;
}
