import {useNavigate, useSearchParams} from 'react-router-dom';
import {showModal} from '../../../store/actions';
import {ModalType} from '../../../types/modalType';
import {useAppDispatch} from '../../../hooks';
import {saveResetEmail, saveResetToken} from '../../../services/token';
import {toast} from 'react-toastify';
import {useEffect} from 'react';
import {AppRoute} from '../../../const/common';

export default function Reset() {
  const dispatch = useAppDispatch();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(showModal(ModalType.ResetPassword));

    const resetToken = searchParams.get('token');
    const resetEmail = searchParams.get('email');

    if (!resetToken || !resetEmail) {
      toast('Неверная ссылка, попробуйте снова', {type: 'error'});

      return;
    }

    saveResetToken(resetToken);
    saveResetEmail(resetEmail);

    navigate(AppRoute.Root);
  }, []);

  return null;
}
