import React, {ReactElement} from 'react';
import {Helmet} from 'react-helmet-async';

type SeoProps = {
  title?: string,
  desc?: string,
  url?: string,
}

export default function Seo({title, desc, url}: SeoProps): ReactElement {
  return <Helmet defer={false}>
    title && <title>{title}</title>
    title && <meta property="og:title" content={title}/>

    desc && <meta name="description" content={desc}></meta>
    desc && <meta property="og:description" content={desc}/>

    url && <link rel="canonical" href={url}/>
    url && <meta property="og:url" content={url}/>
  </Helmet>
}
