import {FormEvent, ReactElement, useState} from 'react';
import Logo from '../logo/logo';
import MainNav from '../main-nav/main-nav';
import { ReactComponent as IconReset } from '../../../sprite/icon-reset.svg';
import { ReactComponent as IconSearch } from '../../../sprite/icon-search.svg';
import cn from 'classnames';
import {AppRoute} from '../../../const/common';
import {useNavigate, useSearchParams} from 'react-router-dom';
import MainMenu from '../main-menu/main-menu';

export default function Header(): ReactElement {
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const urlPhraseParam = searchParams.get('phrase');

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [searchValue, setSearchValue] = useState(urlPhraseParam ? urlPhraseParam : '');

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!searchValue) {
      return;
    }

    const url = new URL(AppRoute.Search, window.location.origin);
    url.searchParams.set('phrase', searchValue);

    navigate({
      pathname: AppRoute.Search,
      search: url.search,
    });
  }

  return (
    <div className="main-header__wrap">
      <header
        className={cn(
          'main-header',
          'container',
          {'main-header--search-open': isSearchOpen},
        )}
      >
        <Logo/>
        <form className="main-header__search" autoComplete="off" onSubmit={submitHandler}>
          <p className="main-header__field-wrapper field__wrapper">
            <input
              className="main-header__field field field_view--action field_view--icon field_type--search"
              type="search"
              placeholder="Поиск"
              id="site-search"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
            />
            <label htmlFor="site-search" className="visuallyhidden">
              Поиск по сайту
            </label>
            <button
              className="main-header__field-button main-header__field-button--reset button field__action"
              type="reset"
              onClick={() => {
                setIsSearchOpen(false);
                setSearchValue('');
              }}
            >
              <span className="visuallyhidden">Сброс значения</span>
              <IconReset className="icon" width={24} height={24}/>
            </button>
          </p>
          <button
            className="main-header__search-open button button_view--primary button_size--m button_behavior--hide-text"
            type="button"
            onClick={() => setIsSearchOpen(true)}
          >
            <span className="visuallyhidden">Открыть поисковую строку</span>
            <IconSearch className="icon" width={20} height={20}/>
          </button>
        </form>
        <MainNav/>
      </header>

      <MainMenu/>
    </div>
  );
}
