import Header from '../../components/page/header/header';
import React, {ReactElement} from 'react';
import Breadcrumbs from '../../components/page/breadcrumbs/breadcrumbs';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import PurchaseHistory from '../../components/purchase-history/purchase-history';
import {CanonicalUrl, PageTitle} from '../../const/seo';
import Seo from '../../components/service/seo/seo';

export default function PurchaseHistoryScreen(): ReactElement {
  return (
    <>
      <Seo
        title={PageTitle.PurchaseHistory}
        url={CanonicalUrl.PurchaseHistory}
      />

      <Header/>
      <Breadcrumbs
        firstPage={'История покупок'}
      />
      <PurchaseHistory/>
      <Footer/>
      <ModalContainer />
    </>
  )
}
