import {
  Good,
  GoodServer,
  GoodType,
  GoodTypeList,
  GraphicTypeList,
  PlatformTypeList
} from '../types/goods';
import {Article, ArticleCard, ArticleCardServer, ArticleServer} from '../types/blog';

export const parseGoodsDataFromServer = (data: GoodServer, id: number): Good => {
  const type = GoodTypeList[data.types[0].id - 1];

  const images = data.images.map((image) => {
    return {
      src: image.url,
      alt: '#',
    }
  });

  return {
    id: String(data.id),

    type,

    typeSlug: data.types[0].slug,

    category: data.categories[0],

    desc: data.name,
    fullDesc: data.description,
    images,

    view: data.views,
    purchases: data.purchases,

    genre: `genre-${data.genres[0].id}`,
    nameGenre: data.genres[0].name,
    fullGenre: data.genres[0].name,
    platform: type !== GoodType.Site ? PlatformTypeList[data.platforms[0].id - 1] : undefined,
    graphic: type === GoodType.Game ? GraphicTypeList[data.graphics[0].id - 1] : undefined,

    price: data.price,
    oldPrice: data.old_price,
    priceDev: data.development_cost,

    date: data.published_at,

    stack: data.stacks.map(stack => stack.name),

    isEstimate: data.estimate,
    isAdminPanel: data.admin_panel,

    // contentList: data.compositions.map(stack => stack.name),
    contentList: [
      'Техническое задание (PDF)',
    ],
    analog: data.similar.split(', '),
    pages: data.pages,
  };
}

export const parseArticleCardDataFromServer = (data: ArticleCardServer): ArticleCard => {
  return {
    id: data.id,
    title: data.name,
    slug: data.slug,
    preview: data.poster,
    date: data.published_at,
    view: data.views,
  };
}

export const parseArticleDataFromServer = (data: ArticleServer): Article => {
  return {
    title: data.name,
    content: data.content,
    poster: data.poster,
    readTime: data.read_time,
    views: data.views,
    metaTitle: data.meta_title,
    metaDesc: data.meta_description,
    date: data.published_at,
  };
}
