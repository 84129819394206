import React, {ReactElement} from 'react';
import FormTitle from '../../atomas/form-title/form-title';
import FormSubmitButton from '../../atomas/form-submit-button/form-submit-button';
import {hideModal} from '../../../store/actions';
import Modal from '../modal';
import {useAppDispatch} from '../../../hooks';
import {logoutAction} from '../../../store/api-actions';
import {useForm} from 'react-hook-form';
import {RegistrationData} from '../../../types/data';

export default function ModalExit(): ReactElement {
  const methods = useForm<RegistrationData>();
  const dispatch = useAppDispatch();

  const onSubmit = methods.handleSubmit(data => {
    dispatch(hideModal());
    dispatch(logoutAction());
  });

  return (
    <Modal>
      <form
        className="modal__inner"
        spellCheck="false"
        noValidate
        onSubmit={event => event.preventDefault()}
      >
        <FormTitle>Выйти из аккаунта?</FormTitle>

        <p
          className="input__wrapper input__wrapper--field text_view--tertiary space_bottom-l text_size--14 modal__desc">
          Вы уверены, что хотите выйти из своего аккаунта? <br/> В этом случае, вам придется вводить свои данные снова.
        </p>

        <p className="input__wrapper text_size--14 grid grid_col--2 grid_gap--s-m-m modal__bottom">
          <button
            className="button button_view--tertiary button_size--s text_align--center button_size--full"
            type="button"
            onClick={() => dispatch(hideModal())}
          >
            Отмена
          </button>

          <FormSubmitButton onSubmit={onSubmit}>
            Выход
          </FormSubmitButton>
        </p>
      </form>
    </Modal>
  );
}
