import {ReactElement} from 'react';
import {useAppSelector} from '../../../hooks';
import GoodsCard from '../../goods/goods-card/goods-card';

export default function NewGoods(): ReactElement {
  const goods = useAppSelector((state) => state.goods);
  const hitGoods = goods.slice(3, 6);

  return (
    <>
      <section className="goods-slider space_bottom-xxl">
        <h2 className="goods-slider__title title title_size--m space_bottom-l">
          Новинки ТЗ на разработку
        </h2>

        <ul className="goods-slider__list grid grid_col--1-1-3 grid_gap--s-m-m">
          {hitGoods.map((good) => (
            <GoodsCard
              title={good.desc}
              id={good.id}
              view={good.view}
              purchases={good.purchases}
              genre={good.genre}
              nameGenre={good.nameGenre}
              price={good.price}
              oldPrice={good.oldPrice}
              images={good.images}
              graphic={good.graphic}
              platform={good.platform}
              key={good.id}
              className={'goods-card--narrow'}
            ></GoodsCard>
          ))}
        </ul>
      </section>
    </>
  )
}
