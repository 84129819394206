import React, {ReactElement} from 'react';
import {CanonicalUrl, PageDesc, PageTitle} from '../../const/seo';
import Header from '../../components/page/header/header';
import Footer from '../../components/page/footer/footer';
import ModalContainer from '../../components/page/modal-container/modal-container';
import Blog from '../../components/blog/blog';
import Seo from '../../components/service/seo/seo';

export default function BlogScreen(): ReactElement {
  return (
    <>
      <Seo
        title={PageTitle.Blog}
        desc={PageDesc.Blog}
        url={CanonicalUrl.Blog}
      />

      <Header />
      <Blog/>
      <Footer />
      <ModalContainer />
    </>
  );
}
