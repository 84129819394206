import GoodsCard from '../../goods/goods-card/goods-card';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import Pagination from '../../page/pagination/pagination';
import React, {useEffect, useState} from 'react';
import {scrollTop} from '../../../utils/common';
import Plug from '../../plug/plug';
import PlugReturn from '../../plug/plug-return/plug-return';
import {AppRoute} from '../../../const/common';
import ArticleCardWide from '../../blog/article-card-wide/article-card-wide';
import {useSearchParams} from 'react-router-dom';
import {fetchArticlesCardsAction, fetchSearchGoodsAction} from '../../../store/api-actions';

const RESULTS_PER_PAGE = 6;

export enum SearchType {
  Catalog = 'CATALOG',
  Blog = 'BLOG',
}

export default function SearchList() {
  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();
  const urlPhraseParam = searchParams.get('phrase');
  const urlPageParam = searchParams.get('page');

  const [searchType, setSearchType] = useState(SearchType.Catalog);
  const [currentPage, setCurrentPage] = useState(urlPageParam ? Number(urlPageParam) : 1);
  const [pageCount, setPageCount] = useState(1);


  const goods = useAppSelector((state) => state.searchGoods);
  const pageGoods = goods.slice(
    (currentPage - 1) * RESULTS_PER_PAGE,
    currentPage * RESULTS_PER_PAGE,
  );

  const articleCards = useAppSelector((state) => state.articleCards);
  const articleCount = useAppSelector((state) => state.articlesCount);

  useEffect(() => {
    switch (searchType) {
      case SearchType.Catalog:
        setPageCount(Math.ceil(goods.length / RESULTS_PER_PAGE));
        break;
      case SearchType.Blog:
        setPageCount(Math.ceil(articleCount / RESULTS_PER_PAGE));
        break;
    }
  });

  useEffect(() => {
    const urlParams = new URLSearchParams;

    if (currentPage !== 1) {
      urlParams.set('page', String(currentPage));
    }

    if (urlPhraseParam) {
      urlParams.set('phrase', String(urlPhraseParam));
    }

    setSearchParams(urlParams);

    scrollTop();
  }, [currentPage])

  useEffect(() => {
    switch (searchType) {
      case SearchType.Catalog:
        dispatch(fetchSearchGoodsAction({phrase: urlPhraseParam ? urlPhraseParam : ''}));
        setPageCount(Math.ceil(goods.length / RESULTS_PER_PAGE));
        break;
      case SearchType.Blog:
        dispatch(fetchArticlesCardsAction({perPage: RESULTS_PER_PAGE, currentPage, phrase: urlPhraseParam ? urlPhraseParam : ''}));
        setPageCount(Math.ceil(articleCount / RESULTS_PER_PAGE));
        break;
    }

    setCurrentPage(1);

    scrollTop();
  }, [searchType]);

  useEffect(() => {
    switch (searchType) {
      case SearchType.Catalog:
        dispatch(fetchSearchGoodsAction({phrase: urlPhraseParam ? urlPhraseParam : ''}));
        setPageCount(Math.ceil(goods.length / RESULTS_PER_PAGE));
        break;
      case SearchType.Blog:
        dispatch(fetchArticlesCardsAction({perPage: RESULTS_PER_PAGE, currentPage, phrase: urlPhraseParam ? urlPhraseParam : ''}));
        setPageCount(Math.ceil(articleCount / RESULTS_PER_PAGE));
        break;
    }
  }, [searchParams]);

  return (
    <>
      <form className="catalog__tabs text_size--14 space_bottom-xl">
        <span
          className="catalog__tabs-item"
        >
          <input
            className="tab"
            type="radio"
            name="catalog-tabs"
            id="search-tab-catalog"
            defaultValue="Каталог"
            checked={searchType === SearchType.Catalog}
            onChange={() => setSearchType(SearchType.Catalog)}
          />
          <label htmlFor="search-tab-catalog">
            Товары
          </label>
        </span>

        <span
          className="catalog__tabs-item"
        >
          <input
            className="tab"
            type="radio"
            name="catalog-tabs"
            id="search-tab-blog"
            defaultValue="Каталог"
            checked={searchType === SearchType.Blog}
            onChange={() => setSearchType(SearchType.Blog)}
          />
          <label htmlFor="search-tab-blog">
            Блог
          </label>
        </span>
      </form>

      <ul className="search__list">
        {searchType === SearchType.Catalog && pageGoods.map((good) => (
          <GoodsCard
            title={good.desc}
            id={good.id}
            view={good.view}
            purchases={good.purchases}
            genre={good.genre}
            nameGenre={good.nameGenre}
            price={good.price}
            oldPrice={good.oldPrice}
            images={good.images}
            graphic={good.graphic}
            platform={good.platform}
            key={good.id}
          ></GoodsCard>
        ))}

        {searchType === SearchType.Blog && articleCards.map((article) => (
          <ArticleCardWide
            title={article.title}
            id={article.id}
            date={article.date}
            view={0}
            preview={article.preview}
            slug={article.slug}
            key={article.slug}
          ></ArticleCardWide>
        ))}

        {pageGoods.length === 0 && searchType === SearchType.Catalog && <Plug
          caption={`По запросу «${urlPhraseParam}» ничего не найдено`}
        >
          <PlugReturn
            caption={'Перейти в каталог'}
            link={AppRoute.Root}
          />
        </Plug>}

        {articleCards.length === 0 && searchType === SearchType.Blog && <Plug
          caption={`По запросу «${urlPhraseParam}» ничего не найдено`}
        >
          <PlugReturn
            caption={'Перейти в блог'}
            link={AppRoute.Blog}
          />
        </Plug>}
      </ul>

      <Pagination
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={(page) => setCurrentPage(page)}
      />
    </>
  );
}
