import {Navigate} from 'react-router-dom';
import {AppRoute, AuthStatus} from '../../../const/common';
import {ReactElement} from 'react';
import {useAppDispatch} from '../../../hooks';
import {showModal} from '../../../store/actions';
import {ModalType} from '../../../types/modalType';

type PrivateRouteProps = {
  children: JSX.Element;
  authStatus: AuthStatus;
}

function PrivateRoute(props: PrivateRouteProps): ReactElement {
  const dispatch = useAppDispatch();

  const {authStatus, children} = props;

  //todo Auth
  if (authStatus !== AuthStatus.NoAuth) {
    return children;
  } else {
    dispatch(showModal(ModalType.Login));
    return <Navigate to={AppRoute.Root} />;
  }
}

export default PrivateRoute;
