import React, {ReactElement} from 'react';
import Logo from '../page/logo/logo';
import {ReactComponent as IconWeb} from '../../sprite/icon-web.svg';
import {ReactComponent as IconClick} from '../../sprite/icon-click.svg';
import {ReactComponent as IconCalm} from '../../sprite/icon-emoji-calm.svg';
import {ReactComponent as IconAward} from '../../sprite/icon-award-star.svg';
import {ReactComponent as IconId} from '../../sprite/icon-id-card.svg';

import {ReactComponent as IconDone} from '../../sprite/icon-done.svg';
import {ReactComponent as IconPerson} from '../../sprite/icon-person.svg';
import {ReactComponent as IconTeam} from '../../sprite/icon-team.svg';
import {ReactComponent as IconCalendar} from '../../sprite/icon-calendar-2.svg';
import {ReactComponent as IconArrow} from '../../sprite/icon-arrow-top-right.svg';
import {ReactComponent as IconStar} from '../../sprite/icon-star.svg';
import Picture from '../atomas/picture/picture';
import Input from '../atomas/input/input';
import {ValidationConfig} from '../../const/validation-config';
import Textarea from '../atomas/textarea/textarea';
import FormSubmitButton from '../atomas/form-submit-button/form-submit-button';
import {FormProvider, useForm} from 'react-hook-form';
import {EditProfileData} from '../../types/data';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {toast} from 'react-toastify';
import Breadcrumbs from '../page/breadcrumbs/breadcrumbs';

const FORM_NAME = 'contacts';

export default function About(): ReactElement {
  const methods = useForm<EditProfileData>();
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.user);

  const onSubmit = methods.handleSubmit(data => {
    toast('Форма обратной связи в разработке. Вы можете написать нам на Email', {type: 'warning'});
  });

  return (
    <>
      <Breadcrumbs
        firstPage={'О нас'}
      />

      <main className="about container">
        <h1 className="contacts__title title title_size--l">О нас</h1>

        <div className="about__info space_bottom-xxl grid grid_gap--s-s-m">
          <p className="text_size--14 text_line-height--150">
            Мы рады приветствовать вас в нашем интернет-магазине, где вы можете найти и приобрести лучшие готовые технические задания (ТЗ) в области информационных технологий. TaskStore - это не просто магазин ТЗ, это площадка, где воплощаются ваши IT-проекты в реальность с минимальными усилиями.
            <br/>
            <br/>
            Мы - команда профессионалов с многолетним опытом в сфере разработки программного обеспечения и веб-приложений. Наша цель - сделать вашу работу над проектами более эффективной и комфортной, предоставив готовые и качественные технические задания, которые удовлетворят даже самых требовательных клиентов.
          </p>

          <div className="about__info-logo">
            <Logo/>
          </div>
        </div>

        <section className="about__features space_bottom-xxl">
          <h2 className="goods-slider__title title title_size--m space_bottom-l">
            Наши преимущества
          </h2>

          <hr className="top-category__line space_bottom-l"/>

          <ul className="about__features-list grid grid_gap--s-m-m">
            <li className="top-category__item">
              <div
                className="top-category__link"
              >
                <IconWeb className="icon icon_color--accent" width={34} height={34}/>
                <p className="text_line-height--125 text_weight--500">
                  Широкий выбор ТЗ
                </p>

                <p className="text_size--14 text_line-height--150">
                  Мы предлагаем разнообразные ТЗ для различных типов проектов в IT сфере - от веб-сайтов до мобильных приложений и игр.
                </p>
              </div>
            </li>
            <li className="top-category__item">
              <div
                className="top-category__link"
              >
                <IconClick className="icon icon_color--accent" width={34} height={34}/>
                <p className="text_line-height--125 text_weight--500">
                  Современные тренды и стандарты
                </p>

                <p className="text_size--14 text_line-height--150">
                  Каждое наше ТЗ разработано с учетом последних технологических тенденций и отвечает высоким стандартам качества.
                </p>
              </div>
            </li>
            <li className="top-category__item">
              <div
                className="top-category__link"
              >
                <IconCalm className="icon icon_color--accent" width={34} height={34}/>
                <p className="text_line-height--125 text_weight--500">
                  Экономия времени и ресурсов
                </p>

                <p className="text_size--14 text_line-height--150">
                  Покупка готовых ТЗ у нас позволит вам сэкономить время и силы, которые обычно уходят на составление документации с нуля.
                </p>
              </div>
            </li>
            <li className="top-category__item">
              <div
                className="top-category__link"
              >
                <IconAward className="icon icon_color--accent" width={34} height={34}/>
                <p className="text_line-height--125 text_weight--500">
                  Профессиональный подход
                </p>

                <p className="text_size--14 text_line-height--150">
                  Наша команда состоит из опытных специалистов, готовых помочь вам в выборе и использовании ТЗ, а также обеспечить поддержку на всех этапах вашего проекта.
                </p>
              </div>
            </li>
            <li className="top-category__item">
              <div
                className="top-category__link"
              >
                <IconId className="icon icon_color--accent" width={34} height={34}/>
                <p className="text_line-height--125 text_weight--500">
                  Индивидуальный подход
                </p>

                <p className="text_size--14 text_line-height--150">
                  Мы готовы предложить вам индивидуальные решения и консультации, чтобы ваш проект стал действительно уникальным и успешным.
                </p>
              </div>
            </li>
          </ul>
        </section>

        <section className="about__history space_bottom-xxl">
          <h2 className="goods-slider__title title title_size--m space_bottom-l">
            История нашей компании
          </h2>

          <hr className="top-category__line space_bottom-l"/>

          <ul className="about__history-list grid grid_gap--xxs-xs-xs">
            <li className="about__history-item">
              <dl>
                <dt className="text_view--accent text_weight--700">
                  2005 год
                </dt>
                <dd className="text_size--14 text_line-height--150">
                  Наша история началась в 2005 году, когда группа опытных IT-специалистов объединилась с целью обеспечить российских разработчиков и компании качественными и готовыми к использованию техническими заданиями.
                </dd>
              </dl>
            </li>
            <li className="about__history-item">
              <dl>
                <dt className="text_view--accent text_weight--700">
                  2010 год
                </dt>
                <dd className="text_size--14 text_line-height--150">
                  За пять лет активной работы и поиска оптимальных решений для клиентов, мы расширились и стали одним из ведущих поставщиков готовых ТЗ в IT сфере на российском рынке.
                </dd>
              </dl>
            </li>
            <li className="about__history-item">
              <dl>
                <dt className="text_view--accent text_weight--700">
                  2015 год
                </dt>
                <dd className="text_size--14 text_line-height--150">
                  Наша компания продолжает расти и развиваться, с каждым годом расширяя ассортимент и улучшая качество предлагаемых ТЗ. В 2015 году мы начали активное сотрудничество с крупными разработчиками и компаниями в России, помогая им экономить время и ресурсы на создании проектной документации.
                </dd>
              </dl>
            </li>
            <li className="about__history-item">
              <dl>
                <dt className="text_view--accent text_weight--700">
                  2020 год
                </dt>
                <dd className="text_size--14 text_line-height--150">
                  В период непростых вызовов и изменений в мировой IT-индустрии, наша компания продолжает быть надежным партнером для тысяч клиентов по всей России. Мы с гордостью говорим, что более 10 000 успешных проектов были реализованы с использованием наших готовых ТЗ.
                </dd>
              </dl>
            </li>
            <li className="about__history-item">
              <dl>
                <dt className="text_view--accent text_weight--700">
                  2022 год
                </dt>
                <dd className="text_size--14 text_line-height--150">
                  В 2022 году мы продолжаем укреплять нашу позицию на рынке IT-услуг в России. За это время мы расширили наш ассортимент готовых ТЗ и улучшили процессы работы, чтобы удовлетворить потребности даже самых требовательных клиентов.
                </dd>
              </dl>
            </li>
            <li className="about__history-item">
              <dl>
                <dt className="text_view--accent text_weight--700">
                  2024 год
                </dt>
                <dd className="text_size--14 text_line-height--150">
                  Сегодня, в 2024 году, TaskStore стал неотъемлемой частью IT-сообщества России, предоставляя профессиональные услуги и качественные решения для разработчиков, стартапов и крупных компаний. Наша команда продолжает расти и совершенствоваться, чтобы оставаться лидером в сфере готовых ТЗ в IT.
                </dd>
              </dl>
            </li>
          </ul>
        </section>

        <section className="about__team space_bottom-xxl">
          <h2 className="goods-slider__title title title_size--m space_bottom-l">
            Наша команда
          </h2>

          <hr className="top-category__line space_bottom-l"/>

          <ul className="about__team-list grid grid_gap--s-m-m">
            <li className="about__team-item">
              <div
                className="about__team-item-inner"
              >
                <div className="about__team-item-top">
                  <Picture
                    src={'/img/team/dev-1'}
                    extension={'jpg'}
                    alt={'Иван Иванов'}
                    lazy={true}
                    width={60}
                    height={60}
                    className={'about__team-item-logo'}
                  />
                  <p className="text_line-height--125 text_weight--500">
                    Иван Иванов
                  </p>
                </div>

                <dl className="about__team-item-desc">
                  <dt>Специализация:</dt>
                  <dd>Геймдизайнер</dd>
                  <dt>Стаж:</dt>
                  <dd>12 лет</dd>
                  <dt>Описание:</dt>
                  <dd>Иван является опытным геймдизайнером с богатым опытом в создании уникальных игровых концепций и геймплея. Он активно участвует в разработке ТЗ для игровых проектов всех жанров.</dd>
                </dl>
              </div>
            </li>
            <li className="about__team-item">
              <div
                className="about__team-item-inner"
              >
                <div className="about__team-item-top">
                  <Picture
                    src={'/img/team/dev-2'}
                    extension={'jpg'}
                    alt={'Екатерина Сидорова'}
                    lazy={true}
                    width={60}
                    height={60}
                    className={'about__team-item-logo'}
                  />
                  <p className="text_line-height--125 text_weight--500">
                    Екатерина Сидорова
                  </p>
                </div>

                <dl className="about__team-item-desc">
                  <dt>Специализация:</dt>
                  <dd>Программист</dd>
                  <dt>Стаж:</dt>
                  <dd>10 лет</dd>
                  <dt>Описание:</dt>
                  <dd>Екатерина обладает глубокими знаниями в области бэкенд-разработки и разработки серверной части игровых проектов. Она ответственна за создание надежной и эффективной архитектуры игровых приложений.</dd>
                </dl>
              </div>
            </li>
            <li className="about__team-item">
              <div
                className="about__team-item-inner"
              >
                <div className="about__team-item-top">
                  <Picture
                    src={'/img/team/dev-3'}
                    extension={'jpg'}
                    alt={'Алексей Петров'}
                    lazy={true}
                    width={60}
                    height={60}
                    className={'about__team-item-logo'}
                  />
                  <p className="text_line-height--125 text_weight--500">
                    Алексей Петров
                  </p>
                </div>

                <dl className="about__team-item-desc">
                  <dt>Специализация:</dt>
                  <dd>Художник (Аниматор)</dd>
                  <dt>Стаж:</dt>
                  <dd>8 лет</dd>
                  <dt>Описание:</dt>
                  <dd>Алексей является талантливым аниматором с опытом работы над различными анимационными проектами. Он отвечает за создание креативной анимации персонажей и объектов в играх.</dd>
                </dl>
              </div>
            </li>
            <li className="about__team-item">
              <div
                className="about__team-item-inner"
              >
                <div className="about__team-item-top">
                  <Picture
                    src={'/img/team/dev-4'}
                    extension={'jpg'}
                    alt={'Мария Иванова'}
                    lazy={true}
                    width={60}
                    height={60}
                    className={'about__team-item-logo'}
                  />
                  <p className="text_line-height--125 text_weight--500">
                    Мария Иванова
                  </p>
                </div>

                <dl className="about__team-item-desc">
                  <dt>Специализация:</dt>
                  <dd>Звукорежиссер</dd>
                  <dt>Стаж:</dt>
                  <dd>15 лет</dd>
                  <dt>Описание:</dt>
                  <dd>Мария имеет богатый опыт в области звукового оформления игр. Она занимается созданием атмосферных звуковых эффектов, музыкального сопровождения и диалогов в играх.</dd>
                </dl>
              </div>
            </li>
            <li className="about__team-item">
              <div
                className="about__team-item-inner"
              >
                <div className="about__team-item-top">
                  <Picture
                    src={'/img/team/dev-5'}
                    extension={'jpg'}
                    alt={'Андрей Козлов'}
                    lazy={true}
                    width={60}
                    height={60}
                    className={'about__team-item-logo'}
                  />
                  <p className="text_line-height--125 text_weight--500">
                    Андрей Козлов
                  </p>
                </div>

                <dl className="about__team-item-desc">
                  <dt>Специализация:</dt>
                  <dd>QA-инженер</dd>
                  <dt>Стаж:</dt>
                  <dd>7 лет</dd>
                  <dt>Описание:</dt>
                  <dd>Андрей отвечает за проверку качества игровых проектов и обнаружение ошибок. Он заботится о том, чтобы каждый аспект игры был тщательно протестирован и соответствовал высоким стандартам.</dd>
                </dl>
              </div>
            </li>
            <li className="about__team-item">
              <div
                className="about__team-item-inner"
              >
                <div className="about__team-item-top">
                  <Picture
                    src={'/img/team/dev-6'}
                    extension={'jpg'}
                    alt={'Наталья Смирнова'}
                    lazy={true}
                    width={60}
                    height={60}
                    className={'about__team-item-logo'}
                  />
                  <p className="text_line-height--125 text_weight--500">
                    Наталья Смирнова
                  </p>
                </div>

                <dl className="about__team-item-desc">
                  <dt>Специализация:</dt>
                  <dd>UX/UI дизайнер</dd>
                  <dt>Стаж:</dt>
                  <dd>9 лет</dd>
                  <dt>Описание:</dt>
                  <dd>Наталья занимается созданием удобного и привлекательного пользовательского интерфейса и дизайном игровых элементов для наших проектов.</dd>
                </dl>
              </div>
            </li>
            <li className="about__team-item">
              <div
                className="about__team-item-inner"
              >
                <div className="about__team-item-top">
                  <Picture
                    src={'/img/team/dev-7'}
                    extension={'jpg'}
                    alt={'Дмитрий Кузнецов'}
                    lazy={true}
                    width={60}
                    height={60}
                    className={'about__team-item-logo'}
                  />
                  <p className="text_line-height--125 text_weight--500">
                    Дмитрий Кузнецов
                  </p>
                </div>

                <dl className="about__team-item-desc">
                  <dt>Специализация:</dt>
                  <dd>Программист (Frontend)</dd>
                  <dt>Стаж:</dt>
                  <dd>11 лет</dd>
                  <dt>Описание:</dt>
                  <dd>Дмитрий отвечает за разработку фронтенда игровых проектов, создание интерактивных элементов и визуальное оформление.</dd>
                </dl>
              </div>
            </li>
            <li className="about__team-item">
              <div
                className="about__team-item-inner"
              >
                <div className="about__team-item-top">
                  <Picture
                    src={'/img/team/dev-8'}
                    extension={'jpg'}
                    alt={'Ольга Новикова'}
                    lazy={true}
                    width={60}
                    height={60}
                    className={'about__team-item-logo'}
                  />
                  <p className="text_line-height--125 text_weight--500">
                    Ольга Новикова
                  </p>
                </div>

                <dl className="about__team-item-desc">
                  <dt>Специализация:</dt>
                  <dd>Менеджер проекта</dd>
                  <dt>Стаж:</dt>
                  <dd>13 лет</dd>
                  <dt>Описание:</dt>
                  <dd>Ольга координирует работу команды, управляет проектами и обеспечивает своевременное выполнение задач.</dd>
                </dl>
              </div>
            </li>
          </ul>
        </section>

        <section className="about__team-features space_bottom-xxl">
          <h2 className="goods-slider__title title title_size--m space_bottom-l">
            Наши достижения
          </h2>

          <hr className="top-category__line space_bottom-l"/>

          <ul className="about__team-features-list grid grid_col--3 grid_gap--s-m-m">
            <li className="about__team-features-item text_weight--500 text_line-height--125">
              <IconDone className="icon icon_color--accent icon_view--circle" width={24} height={24}/>

              Более 10 000 успешных проектов
            </li>
            <li className="about__team-features-item text_weight--500 text_line-height--125">
              <IconPerson className="icon icon_color--accent icon_view--circle" width={24} height={24}/>

              100% удовлетворенных клиентов
            </li>
            <li className="about__team-features-item text_weight--500 text_line-height--125">
              <IconTeam className="icon icon_color--accent icon_view--circle" width={24} height={24}/>

              50+ партнеров и клиентов
            </li>
            <li className="about__team-features-item text_weight--500 text_line-height--125">
              <IconCalendar className="icon icon_color--accent icon_view--circle" width={24} height={24}/>

              95% выполнение проектов в срок
            </li>
            <li className="about__team-features-item text_weight--500 text_line-height--125">
              <IconArrow className="icon icon_color--accent icon_view--circle" width={24} height={24}/>

              30% увеличение объема продаж за последний год
            </li>
            <li className="about__team-features-item text_weight--500 text_line-height--125">
              <IconStar className="icon icon_color--accent icon_view--circle" width={24} height={24}/>

              5-звездочные отзывы от специалистов в области IT
            </li>
          </ul>
        </section>

        <section className="about__form space_bottom-5xl">
          <FormProvider {...methods}>
            <form
              className="contacts__form"
              spellCheck="false"
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <h2 className="title title_size--m space_bottom-l">
                Мы готовы вам помочь
              </h2>

              <p className="contacts__form-desc text text_weight--500 space_bottom-l">
                Свяжитесь с нами для консультации или заказа ТЗ
              </p>

              <div className="grid grid_gap--s-s-m">
                <div className="grid grid_col--2 grid_gap--s-s-m">
                  <Input
                    formName={FORM_NAME}
                    name={'name'}
                    label={'Имя'}
                    placeholder={'Введите ваше имя'}
                    type={'text'}
                    validation={ValidationConfig.name}
                    value={userData?.name}
                  />

                  <Input
                    formName={FORM_NAME}
                    name={'email'}
                    label={'Email'}
                    placeholder={'Введите ваш Email'}
                    type={'email'}
                    validation={ValidationConfig.email}
                    value={userData?.email}
                  />
                </div>

                <Textarea
                  formName={FORM_NAME}
                  name={'message'}
                  label={'Ваше сообщение'}
                  placeholder={'Сообщение'}
                />

                <FormSubmitButton onSubmit={onSubmit}>
                  Отправить
                </FormSubmitButton>
              </div>
            </form>
          </FormProvider>
        </section>
      </main>
    </>
  );
}
