import React, {ReactElement} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import FormTitle from '../../atomas/form-title/form-title';
import {ValidationConfig} from '../../../const/validation-config';
import FormSubmitButton from '../../atomas/form-submit-button/form-submit-button';
import FormCloseButton from '../../atomas/form-close-button/form-close-button';
import {hideModal} from '../../../store/actions';
import Modal from '../modal';
import {ConfirmResetData} from '../../../types/data';
import {useAppDispatch} from '../../../hooks';
import InputPassword from '../../atomas/input-password/input-password';
import {getResetEmail, getResetToken} from '../../../services/token';
import {toast} from 'react-toastify';
import {confirmResetAction} from '../../../store/api-actions';

const FORM_NAME = 'reset-password';

export default function ModalResetPassword(): ReactElement {
  const methods = useForm<ConfirmResetData>();
  const dispatch = useAppDispatch();

  const onSubmit = methods.handleSubmit(data => {
    const token = getResetToken();
    const email = getResetEmail();

    if (!token) {
      toast('Что-то пошло не так, попробуйте снова перейти по ссылке из письма.', {type: 'error'});
    }

    data.token = token;
    data.email = email;

    dispatch(confirmResetAction(data));
  });

  return (
    <Modal>
      <FormProvider {...methods}>
        <form
          className="modal__inner"
          spellCheck="false"
          noValidate
          onSubmit={event => event.preventDefault()}
        >
          <FormTitle>Восстановление доступа</FormTitle>

          <p
            className="input__wrapper input__wrapper--field text_view--tertiary space_bottom-l text_size--14 modal__desc">
            Введите новый пароль для вашего аккаунта
          </p>

          <InputPassword
            formName={FORM_NAME}
            name={'password'}
            label={'Пароль'}
            placeholder={'Введите ваш пароль'}
            validation={ValidationConfig.password}
          />

          <InputPassword
            formName={FORM_NAME}
            name={'password_confirmation'}
            label={'Повторите пароль'}
            placeholder={'Введите ваш пароль'}
            validation={ValidationConfig.password}
            isConfirmPassword={true}
          />

          <FormSubmitButton onSubmit={onSubmit}>
            Подтвердить
          </FormSubmitButton>

          <FormCloseButton onClick={() => dispatch(hideModal())}/>
        </form>
      </FormProvider>
    </Modal>
  );
}
