import {ReactComponent as IconHome} from '../../../sprite/icon-home.svg';
import {ReactComponent as IconFolder} from '../../../sprite/icon-folder.svg';
import {ReactComponent as IconBook} from '../../../sprite/icon-book-2.svg';
import {ReactComponent as IconQuestion} from '../../../sprite/icon-question.svg';
import {ReactComponent as IconContacts} from '../../../sprite/icon-contacts.svg';
import {ReactComponent as IconPerson} from '../../../sprite/icon-person-2.svg';
import {ReactComponent as IconArrowBottom} from '../../../sprite/icon-arrow-bottom.svg';
import {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import {AppRoute} from '../../../const/common';
import {GoodType, GoodTypeName} from '../../../types/goods';
import {useAppSelector} from '../../../hooks';
import {getCategoryList} from '../../../utils/category';

type categoryListProps = {
  [slug: string]: string,
};

const createCategoryList = (categoryName: GoodType, categoryList: categoryListProps, key: string): ReactElement => {
  return (
    <li className="main-header__services-item">
      <h3 className="text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-s">
        <Link to={`${AppRoute.Catalog}${GoodTypeName[categoryName].slug}`} className="link link_view--primary link_view--hover-accent">
          {GoodTypeName[categoryName].name}
        </Link>
      </h3>
      <ul className="main-header__services-sublist grid grid_gap--3xs">
        {Object.entries(categoryList).map(([slug, name]) => {
          return (
            <li className="main-header__services-subitem">
              <Link to={`${AppRoute.Catalog}${GoodTypeName[categoryName].slug}/${slug}`} className="link link_view--primary link_view--hover-accent text_size--14" key={slug}>
                {name}
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  )
}


export default function MainMenu(): ReactElement {
  const goods = useAppSelector((state) => state.goods);

  const goodsCategory = getCategoryList(goods);

  return (
    <>
      <nav className="main-header__menu">
        <ul className="main-header__menu-list container">
          <li className="main-header__menu-item">
            <Link
              to={AppRoute.Root}
              className="main-header__menu-link link link_view--primary link_view--hover-accent text_size--16 text_line-height--150"
            >
              <IconHome className="icon" width={20} height={20}/>
              <span className="main-header__menu-item-text">
                Главная
              </span>
            </Link>
          </li>

          <li className="main-header__menu-item main-header__menu-item--services">
            <Link
              to={AppRoute.Catalog}
              className="main-header__menu-link link link_view--primary link_view--hover-accent text_size--16 text_line-height--150"
            >
              <IconFolder className="icon" width={20} height={20}/>

              <span className="main-header__menu-item-text">
                Каталог
              </span>

              <IconArrowBottom className="icon main-header__menu-item--services-arrow" width={20} height={20}/>
            </Link>

            <ul className="main-header__services grid grid_col--3 grid_gap--s-m-m">
              {
                Object.values(GoodType).map((type) => {
                  if (Object.keys(goodsCategory[type]).length === 0) {
                    return;
                  }

                  return createCategoryList(type, goodsCategory[type], `category-${type}`)
                })
              }
            </ul>
          </li>

          <li className="main-header__menu-item">
            <Link
              to={AppRoute.Blog}
              className="main-header__menu-link link link_view--primary link_view--hover-accent text_size--16 text_line-height--150"
            >
              <IconBook className="icon" width={20} height={20}/>
              <span className="main-header__menu-item-text">
                Блог
              </span>
            </Link>
          </li>

          <li className="main-header__menu-item">
            <Link
              to={AppRoute.Faq}
              className="main-header__menu-link link link_view--primary link_view--hover-accent text_size--16 text_line-height--150"
            >
              <IconQuestion className="icon" width={20} height={20}/>
              <span className="main-header__menu-item-text">
                FAQ
              </span>
            </Link>
          </li>

          <li className="main-header__menu-item">
            <Link
              to={AppRoute.Contacts}
              className="main-header__menu-link link link_view--primary link_view--hover-accent text_size--16 text_line-height--150"
            >
              <IconContacts className="icon" width={20} height={20}/>
              <span className="main-header__menu-item-text">
                Контакты
              </span>
            </Link>
          </li>

          <li className="main-header__menu-item">
            <Link
              to={AppRoute.About}
              className="main-header__menu-link link link_view--primary link_view--hover-accent text_size--16 text_line-height--150"
            >
              <IconPerson className="icon" width={20} height={20}/>
              <span className="main-header__menu-item-text">
                О нас
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
}
