import React, {ReactElement, useState} from 'react';
import { ReactComponent as IconHidden } from '../../../sprite/icon-hidden.svg';
import { ReactComponent as IconVisible } from '../../../sprite/icon-visible.svg';
import cn from 'classnames';
import {FieldValues, RegisterOptions, useFormContext} from 'react-hook-form';

type InputPasswordProps = {
  value?: string;
  formName: string;
  name: string;
  label: string;
  placeholder: string;
  validation?: RegisterOptions<FieldValues, string>;
  isConfirmPassword?: boolean;
  className?: string;
}

export default function InputPassword({formName, name, label, placeholder, validation, isConfirmPassword = false, className = ''}: InputPasswordProps): ReactElement {
  const {
    register,
    watch,
    formState: {errors},
  } = useFormContext();

  const error = errors[name];

  const errorMessage = error ? error.message : '';
  const id = `${formName}-${name}`;

  const [isPasswordShow, setIsPasswordShow] = useState(false);

  return (
    <p
      className={cn(
        'input__wrapper',
        'input__wrapper--field',
        'text_view--tertiary',
        'space_bottom-l',
        className
      )}
    >
      <label
        className="input__description text_size--14 text_line-height--140"
        htmlFor={id}
      >
        {label}
      </label>
      <span className="field__wrapper">
        <input
          className={cn(
            'field',
            {'field_state--error': error}
          )}
          id={id}
          type={isPasswordShow ? 'text' : 'password'}
          placeholder={placeholder}
          required={true}

          {...register(name, {
            ...validation,
            validate: (val: string) => {
              if (!isConfirmPassword) {
                return;
              }

              if (watch('password') !== val) {
                return 'Пароли не совпадают';
              }
            }
          })}
        />
        {error && <b className="field__message">
          {String(errorMessage)}
        </b>}

        <button
          className={cn(
            'button',
            'field__action',
            'field__action--bordered',
            {'field__action--first': isPasswordShow},
            {'field__action--second': !isPasswordShow},
          )}
          type="button"
          onClick={() => setIsPasswordShow((isPasswordShow) => !isPasswordShow)}
        >
          <IconHidden className="icon" width={24} height={24}/>
          <IconVisible className="icon" width={24} height={24}/>
        </button>
      </span>
    </p>
  );
}
