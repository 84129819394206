import {useAppDispatch, useAppSelector} from '../../../hooks';
import {useNavigate, useParams} from 'react-router-dom';
import {AppRoute, AuthStatus} from '../../../const/common';
import {GoodType, GraphicName, PlatformName} from '../../../types/goods';
import {ReactComponent as IconCart} from '../../../sprite/icon-cart.svg';
import {ReactComponent as IconEye} from '../../../sprite/icon-eye.svg';
import {ReactComponent as IconArrowBottom} from '../../../sprite/icon-arrow-bottom.svg';
import {ReactComponent as IconInfo} from '../../../sprite/icon-info.svg';
import {ReactComponent as IconClose} from '../../../sprite/icon-close.svg';
import React, {useEffect, useRef, useState} from 'react';
import {convertTriads, enableScroll, preventScroll} from '../../../utils/common';
import GoodsCardSlider from '../goods-card-slider/goods-card-slider';
import cn from 'classnames';
import {addCartGood, setPreventScroll, showModal} from '../../../store/actions';
import {ModalType} from '../../../types/modalType';
import {toast} from 'react-toastify';
import Seo from '../../service/seo/seo';

export default function GoodsPopup() {
  const dispatch = useAppDispatch();

  const authStatus = useAppSelector((state) => state.authStatus);

  useEffect(() => {
    preventScroll();

    return () => enableScroll();
  }, []);

  const descRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (descRef.current) {
      setIsShowMoreVisible(
        descRef.current.scrollHeight > descRef.current.clientHeight
      );
    }
  }, [descRef]);

  const [isNoteShow, setIsNoteShow] = useState(false);
  const [isDescOpen, setIsDescOpen] = useState(false);
  const [isShowMoreVisible, setIsShowMoreVisible] = useState(true);

  const navigate = useNavigate();

  const goods = useAppSelector((state) => state.goods);
  const cartGoods = useAppSelector((state) => state.cartGoods);
  const {id} = useParams();

  const goodsData = goods.find((goods) => goods.id === id);

  useEffect(() => {
    if (!goodsData && goods.length !== 0) {
      navigate(AppRoute.Error);
    }
  }, [goods]);

  const popupBackClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = event.target as Element;

    if (target.classList.contains('goods-popup__wrap')) {
      dispatch(setPreventScroll(true));
      if (history.length !== 1) {
        history.back();
      } else {
        navigate(AppRoute.Catalog);
      }
    }
  }

  const detailsClickHandler = (event: React.MouseEvent<HTMLDetailsElement, MouseEvent>) => {
    if (window.innerWidth >= 1200) {
      event.preventDefault();
    }
  }

  return (
    <>
      {goodsData && <Seo
        title={`${goodsData.desc} — купить готовое ТЗ по низкой цене`}
        desc={`Готовое техническое задание — ${goodsData.desc}. Узнайте цену, посмотрите детальное описание и состав заказа. Купите прямо сейчас и ускорьте свой проект.`}
        url={window.location.href}
      />}

      {goodsData && <div
        className="goods-popup__wrap goods-popup__wrap--open"
        onClick={popupBackClickHandler}
      >
        <article className="goods-popup">
          <h1 className="title title_size--xs space_bottom-l">
            {goodsData.desc}
          </h1>
          <div className="goods-popup__left-col">
            <GoodsCardSlider
              images={goodsData.images}
              prefix={'goods-popup'}
            />
          </div>
          <div className="goods-popup__right-col">
            <ul className="goods-popup__stat space_bottom-l space_bottom-l">
              <li className="goods-popup__stat-item">
                <dl>
                  <dd className="text_size--14">
                    <IconCart className="icon" width={20} height={20}/>
                    ({goodsData.purchases})
                  </dd>
                  <dt className="text_view--tertiary text_size--12">Купили</dt>
                </dl>
              </li>
              <li className="goods-popup__stat-item">
                <dl>
                  <dd className="text_size--14">
                    <IconEye className="icon" width={20} height={20}/>
                    ({goodsData.view})
                  </dd>
                  <dt className="text_view--tertiary text_size--12">Просмотров</dt>
                </dl>
              </li>
            </ul>
            <details
              className="goods-popup__spoiler space_bottom-l"
              open={true}
              onClick={detailsClickHandler}
            >
              <summary
                className="goods-popup__spoiler-title text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m">
                Общее описание
                <IconArrowBottom className="icon" width={24} height={24}/>
              </summary>
              <div
                className={cn(
                  'goods-popup__compress',
                  'text_size--14',
                  'text_line-height--150',
                  {'goods-popup__compress--open': isDescOpen}
                )}
              >
                <p
                  className="goods-popup__compress-text"
                  ref={descRef}
                >
                  {goodsData.fullDesc}
                </p>
                {isShowMoreVisible && <button
                  className="goods-popup__compress-more button button_size--narrow text_view--accent goods-popup__compress-more--visible"
                  type="button"
                  onClick={() => setIsDescOpen((prevState) => !prevState)}
                >
                  Показать еще
                  <IconArrowBottom className="icon" width={24} height={24}/>
                </button>}
              </div>
            </details>
            <details
              className="goods-popup__spoiler space_bottom-l"
              open={true}
              onClick={detailsClickHandler}
            >
              <summary
                className="goods-popup__spoiler-title text_size--16 text_line-height--150 text_weight--500 text_view--base space_bottom-m">
                Информация
                <IconArrowBottom className="icon" width={24} height={24}/>
              </summary>
              <ul className="goods-popup__info text_size--14 text_line-height--140">
                <li>
                  <dl>
                    <dt>{goodsData.type === GoodType.Game ? 'Жанр' : 'Вид'}</dt>
                    <dd>{goodsData.nameGenre}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>Рекомендуемый стек технологий</dt>
                    <dd>{goodsData.stack.join(', ')}</dd>
                  </dl>
                </li>
                {goodsData.graphic && <li>
                  <dl>
                    <dt>Графика</dt>
                    <dd>{GraphicName[goodsData.graphic]}</dd>
                  </dl>
                </li>}
                {goodsData.style && <li>
                  <dl>
                    <dt>Стиль</dt>
                    <dd>{goodsData.style}</dd>
                  </dl>
                </li>}
                {goodsData.platform && <li>
                  <dl>
                    <dt>Платформа</dt>
                    <dd>{PlatformName[goodsData.platform]}</dd>
                  </dl>
                </li>}
                <li>
                  <dl>
                    <dt>Количество страниц</dt>
                    <dd>{goodsData.pages}</dd>
                  </dl>
                </li>
                {goodsData.priceDev && <li>
                  <dl>
                    <dt>Стоимость разработки</dt>
                    <dd>{convertTriads(goodsData.priceDev) + ' ₽'}</dd>
                  </dl>
                </li>}
                <li>
                  <dl>
                    <dt>Административная панель</dt>
                    <dd>{goodsData.isAdminPanel ? 'Да' : 'Нет'}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>Смета</dt>
                    <dd>{goodsData.isEstimate ? 'Да' : 'Нет'}</dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>Состав заказа</dt>
                    <dd>
                      <ul>
                        {goodsData.contentList.map((content, index) => (
                          <li key={index}>{content}</li>
                        ))}
                      </ul>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl>
                    <dt>Аналоги</dt>
                    <dd>{goodsData.analog.join(', ')}</dd>
                  </dl>
                </li>
              </ul>
            </details>
            <div className="goods-popup__purchase">
              <p className="visuallyhidden">Цена</p>
              <p className="goods-popup__prices">
                <del className="goods-popup__prices-old text_size--14 text_view--tertiary">
                  {convertTriads(goodsData.oldPrice) + ' ₽'}
                </del>
                <span
                  className="goods-popup__prices-new text_size--20 text_line-height--100 text_weight--500 text_view--accent">
                {convertTriads(goodsData.price) + ' ₽'}
              </span>
              </p>
              <button
                className="goods-popup__note-open button button_view--tertiary button_size--s"
                onMouseOver={() => setIsNoteShow(true)}
                onMouseOut={() => setIsNoteShow(false)}
              >
                <IconInfo className="icon" width={24} height={24}/>
              </button>
              <a
                className="goods-popup__purchase-button button button_view--primary button_size--s"
                onClick={(event) => {
                  event.preventDefault();
                  if (authStatus === AuthStatus.Auth && goodsData && !cartGoods.find((cartGood) => cartGood.id === goodsData.id)) {
                    dispatch(addCartGood(
                      goodsData
                    ));

                    toast('Товар добавлен в корзину', {type: 'success'});
                  } else if (cartGoods.find((cartGood) => cartGood.id === goodsData.id)) {
                    toast('Товар уже в корзине', {type: 'warning'});
                  } else {
                    navigate(AppRoute.Root);
                    dispatch(showModal(ModalType.Login));
                  }
                }}
              >
                <IconCart className="icon" width={24} height={24}/>
                <span className="goods-popup__purchase-button-text">Купить</span>
                <span className="goods-popup__purchase-button-price">
                {goodsData.price + ' ₽'}
              </span>
              </a>
            </div>
            <p
              className={cn(
                'goods-popup__note',
                'text_size--14',
                'text_line-height--150',
                {'goods-popup__compress-more--visible': isNoteShow}
              )}
            >
              После оплаты все исходные материалы будут направлены вам на почту.
            </p>
          </div>
          <button
            className="button button_view--simple goods-popup__close"
            type="button"
            onClick={() => {
              dispatch(setPreventScroll(true));
              history.back();
            }}
          >
          <span className="goods-popup__close-text text_size--14 text_line-height--140">
            Закрыть
          </span>
            <IconClose className="icon" width={24} height={24}/>
          </button>
        </article>
      </div>}
    </>
  );
}
