import {useNavigate, useSearchParams} from 'react-router-dom';
import {checkAuthAction} from '../../../store/api-actions';
import {AppRoute} from '../../../const/common';
import {useEffect} from 'react';
import {saveToken} from '../../../services/token';
import {store} from '../../../store';
import {toast} from 'react-toastify';

export default function Verify() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      saveToken(token);

      store.dispatch(checkAuthAction());

      toast('Почта подтверждена', {type: 'success'});
    }

    navigate(AppRoute.Root);
  }, []);

  return null;
}
