export enum ScrollPosition {
  Top = 'TOP',
  Catalog = 'CATALOG',
}

export const scrollTop = (scrollPosition: ScrollPosition = ScrollPosition.Top): void => {
  switch (scrollPosition) {
  case ScrollPosition.Catalog:
    const catalogNode = document.querySelector('.catalog');
    catalogNode && catalogNode.scrollIntoView();
    break;
  case ScrollPosition.Top:
    window.scrollTo(0, 0);
    break;
  }
};

export const preventScroll = () => {
  document.documentElement.style.setProperty('--scroll-width', `${window.innerWidth - document.body.clientWidth}px`);

  document.body.classList.add('no-scroll');
};

export const enableScroll = () => {
  document.documentElement.style.removeProperty('--scroll-width');

  document.body.classList.remove('no-scroll');
};

export const convertTriads = (number: string | number): string => {
  const str = String(number);

  return str.replace(/(\d{5,})/, (text, $1) => $1.replace(/(?<=\d)(?=(\d{3})+$)/g, ' '));
};

export const getRandomSubarray = (array: any[], count: number) => {
  const shuffled = array.sort(() => 0.5 - Math.random());

  return shuffled.slice(0, count);
}
